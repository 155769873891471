const uniqueBy = <T>(array: T[], key: keyof T): T[] => {
  const map = new Set();

  const result = array.filter(data => {
    if (map.has(data[key])) return false;

    map.add(data[key]);

    return true;
  });

  map.clear();

  return result;
};

export { uniqueBy };
