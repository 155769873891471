import { routes } from '@shared/config';

import type { TabsRouterTab } from '@shared/ui';

const tabs: TabsRouterTab[] = [
  {
    label: 'Bucket',
    route: routes.bucket()
  },
  {
    label: 'Upload',
    route: routes.upload()
  },
  {
    label: 'Sharepoint',
    route: routes.sharepoint(),
    disabled: true
  },
  {
    label: 'Directories',
    route: routes.directories()
  }
];

export { tabs };
