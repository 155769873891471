import type { ReactNode } from 'react';

import { useDropzone } from 'react-dropzone';

import { ManageFileMetaGrid } from '@features/manage-file-meta';

import { extensionMap, uploadFileModel } from '@features/upload-local-file';

import { createView } from '@shared/lib/view';

import { Container } from '@shared/ui';

import { manageMetaModel, page } from './model';

import { TableDropZoneArea } from './table-dropzone-area.component';

const UploadPage = createView()
  .open(page.mounted)
  .close(page.unmounted)
  .view(() => (
    <UploadDropzone>
      <UploadLocalFilesDataGrid />
    </UploadDropzone>
  ));

const UploadDropzone = createView<{ children: ReactNode }>()
  .units({
    noClick: manageMetaModel.$metasExist,
    onChange: uploadFileModel.fileInputChanged,
    onError: uploadFileModel.fileInputErrored
  })
  .map(({ onChange, onError, noClick }) => ({
    dropzoneModel: useDropzone({
      multiple: true,
      accept: extensionMap,
      noClick: noClick,
      maxSize: 25_242_880,
      onDropAccepted: onChange,
      onDropRejected: onError,
      validator: file => {
        if (file.name.match(/[*?\/\\|><₴,()[\]{};:'"~`!@#$%^&№А-Яа-яіІёЁ]/g)) {
          return {
            code: 'invalid-name',
            message: `Name contains spec or non-latin chars`
          };
        }

        return null;
      }
    })
  }))
  .view(({ dropzoneModel, children }) => (
    <Container {...dropzoneModel.getRootProps({ refKey: 'innerRef' })}>
      <input {...dropzoneModel.getInputProps()} />

      <TableDropZoneArea
        isDragActive={dropzoneModel.isDragActive}
        isDragAccept={dropzoneModel.isDragAccept}
      />

      {children}
    </Container>
  ));

const UploadLocalFilesDataGrid = createView()
  .open(manageMetaModel.page.mounted)
  .close(manageMetaModel.page.unmounted)
  .view(() => <ManageFileMetaGrid model={manageMetaModel} />);

export { UploadPage };
