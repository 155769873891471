import { createStore } from 'effector';

import { api, http } from './api';

const $accessToken = createStore('').on(
  [
    api.auth.verifyMfaToken.doneData,
    api.auth.loginComplete.doneData,
    api.auth.refreshAccessTokens.doneData
  ],
  (_, { data: { accessToken } }) => accessToken
);

const $authHeaders = $accessToken.map(token => ({
  Authorization: `Bearer ${token}`
}));

http.headers({
  auth: $authHeaders
});
