type MFARoutes = {
  (): string;
  setup: () => string;
  confirm: () => string;
};

const mfaRoutes: MFARoutes = () => '/mfa';
mfaRoutes.setup = () => '/mfa/setup';
mfaRoutes.confirm = () => '/mfa/confirm';

export { mfaRoutes };
