import { createEvent, createStore } from 'effector';

const createPage = () => {
  const mounted = createEvent();
  const unmounted = createEvent();

  const $isMounted = createStore(false)
    .on(mounted, () => true)
    .reset(unmounted);

  return { mounted, unmounted, $isMounted };
};

export { createPage };
