/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {environment} from "@shared/config";
import {ContentType, createHttpApi} from 'effector-http-api';

export interface MFAOptiontypeDto {
  DeliveryMedium?: string;
  AttributeName?: string;
}

export interface AttributesDto {
  sub: string;
  givenName: string;
  familyName: string;
  email: string;
}

export interface CustomAttributesDto {
  functionsRead?: string[];
  functionsWrite?: string[];
  marketsRead?: string[];
  marketsWrite?: string[];
}

export interface UserDto {
  Username?: string;

  /** @format date-time */
  UserCreateDate?: string;

  /** @format date-time */
  UserLastModifiedDate?: string;
  Enabled?: boolean;
  UserStatus?: string;
  MFAOptions?: MFAOptiontypeDto[];
  attributes: AttributesDto;
  customAttributes: CustomAttributesDto;
}

export interface GetUsersListResponseDto {
  users: UserDto[];
  nextToken?: string;
}

export interface GetUserByEmailResponseDto {
  Username?: string;

  /** @format date-time */
  UserCreateDate?: string;

  /** @format date-time */
  UserLastModifiedDate?: string;
  Enabled?: boolean;
  UserStatus?: string;
  MFAOptions?: MFAOptiontypeDto[];
  attributes: AttributesDto;
  customAttributes: CustomAttributesDto;
  groups: string[];
}

export interface CustomPermissionsDto {
  functionsRead: string[];
  functionsWrite: string[];
  marketsRead: string[];
  marketsWrite: string[];
}

export interface UpdateUserAttributesDto {
  firstName: string;
  lastName: string;
  customAttributes: CustomPermissionsDto;
}

export interface UserGroupResponse {
  /** @format date-time */
  createdAt: string;
  name: string;

  /** @format date-time */
  updatedAt: string;
  description?: string;
}

export interface GetUsergroupListResponseDto {
  groups: UserGroupResponse[];
  nextToken?: string;
}

export interface AddUserToGroupBodyDto {
  email: string;
  usergroup: string;
}

export interface FunctionDto {
  id: string;
  name: string;
}

export interface MarketDto {
  id: string;
  name: string;
}

export interface DirectoryDto {
  function: FunctionDto[];
  market: MarketDto[];
}

export interface PutMarketsDto {
  markets: MarketDto[];
}

export interface PutFunctionsDto {
  functions: MarketDto[];
}

export interface UploadResultDto {
  url: string;
  attempts?: number;
  cfId?: object;
  extendedRequestId?: string;
  httpStatusCode?:
    | 'CONTINUE'
    | 'SWITCHING_PROTOCOLS'
    | 'PROCESSING'
    | 'EARLYHINTS'
    | 'OK'
    | 'CREATED'
    | 'ACCEPTED'
    | 'NON_AUTHORITATIVE_INFORMATION'
    | 'NO_CONTENT'
    | 'RESET_CONTENT'
    | 'PARTIAL_CONTENT'
    | 'AMBIGUOUS'
    | 'MOVED_PERMANENTLY'
    | 'FOUND'
    | 'SEE_OTHER'
    | 'NOT_MODIFIED'
    | 'TEMPORARY_REDIRECT'
    | 'PERMANENT_REDIRECT'
    | 'BAD_REQUEST'
    | 'UNAUTHORIZED'
    | 'PAYMENT_REQUIRED'
    | 'FORBIDDEN'
    | 'NOT_FOUND'
    | 'METHOD_NOT_ALLOWED'
    | 'NOT_ACCEPTABLE'
    | 'PROXY_AUTHENTICATION_REQUIRED'
    | 'REQUEST_TIMEOUT'
    | 'CONFLICT'
    | 'GONE'
    | 'LENGTH_REQUIRED'
    | 'PRECONDITION_FAILED'
    | 'PAYLOAD_TOO_LARGE'
    | 'URI_TOO_LONG'
    | 'UNSUPPORTED_MEDIA_TYPE'
    | 'REQUESTED_RANGE_NOT_SATISFIABLE'
    | 'EXPECTATION_FAILED'
    | 'I_AM_A_TEAPOT'
    | 'MISDIRECTED'
    | 'UNPROCESSABLE_ENTITY'
    | 'FAILED_DEPENDENCY'
    | 'PRECONDITION_REQUIRED'
    | 'TOO_MANY_REQUESTS'
    | 'INTERNAL_SERVER_ERROR'
    | 'NOT_IMPLEMENTED'
    | 'BAD_GATEWAY'
    | 'SERVICE_UNAVAILABLE'
    | 'GATEWAY_TIMEOUT'
    | 'HTTP_VERSION_NOT_SUPPORTED';
  requestId?: string;
  totalRetryDelay?: number;
}

export interface DownloadFileDto {
  key?: string;
}

export interface FileMetaDto {
  /** @pattern 20xx */
  service_year?: string;
  service_month?:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '9'
    | '10'
    | '11'
    | '12';
  market?: string;
  function?: string;
  description?: string;
  from?: string;
  subject?: string;
  type?: string;

  /** @format date-time */
  validation_date?: string;
  validated_by?: string;
  sharepoint_id?: string;
  sharepoint_link?: string;
  validated: object;
}

export interface UpdateFileMetaDto {
  key: string;
  metadata: FileMetaDto;
}

export interface DeleteFileParamsDto {
  key: string;
}

export interface MetadataResponceDto {
  market: string;
  subject: string;
  type: string;
  sharepoint_id: string;
  validated_by: string;
  validated: object;

  /** @format date-time */
  validation_date: string;
  service_year: object;
  function: string;
  description: string;
  from: string;
  sharepoint_link: string;
  service_month: object;
}

export interface DocsResponceDto {
  Key: string;

  /** @format date-time */
  LastModified: string;
  ETag: string;
  Size: number;
  StorageClass: string;
  Metadata: MetadataResponceDto;
  relativePath: string;
}

export interface LoginInitResponseDto {
  /** @example SOFTWARE_TOKEN_MFA */
  challengeName:
    | 'ADMIN_NO_SRP_AUTH'
    | 'CUSTOM_CHALLENGE'
    | 'DEVICE_PASSWORD_VERIFIER'
    | 'DEVICE_SRP_AUTH'
    | 'MFA_SETUP'
    | 'NEW_PASSWORD_REQUIRED'
    | 'PASSWORD_VERIFIER'
    | 'SELECT_MFA_TYPE'
    | 'SMS_MFA'
    | 'SOFTWARE_TOKEN_MFA';
  session: string;
}

export interface LoginInitParamsDto {
  email: string;
  password: string;
}

export interface LoginCompleteResponseDto {
  expiresIn: number;
  accessToken: string;
  refreshToken: string;
}

export interface LoginCompleteParamsDto {
  totp?: string;
  session?: string;
  email?: string;
}

export interface LoginResponseDto {
  accessToken?: string;
  refreshToken?: string;
}

export interface RefreshTokenDto {
  refreshToken: string;
}

export interface LogoutBodyDto {
  refreshToken: string;
}

export interface GetUserInfoResponceDto {
  email: string;
  firstName: string;
  lastName: string;
  mfaEnabled: boolean;
  groups: string[];
  customAttributes:CustomAttributesDto
}

export interface CodeDeliveryDetailsType {
  DeliveryMedium?: 'EMAIL' | 'SMS';
  Destination?: string;
  AttributeName?: string;
}

export interface SignupResponseDto {
  userConfirmed: boolean;
  deliveryDetails: CodeDeliveryDetailsType;
}

export interface SignupBodyDto {
  /**
   *
   *       8-character minimum length,
   *       Contains at least 1 number,
   *       Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + = ),
   *       Contains at least 1 lowercase letter,
   *       Contains at least 1 uppercase letter
   */
  password: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ConfirmEmailDto {
  code: string;
  email: string;
}

export interface ResendVerificationCodeDto {
  email: string;
}

export interface AssociateMFATokenResponseDto {
  session: string;
  qrcode: string;
}

export interface AssociateMFATokenParamsDto {
  session: string;
  email: string;
}

export interface VerifyMFATokenResponseDto {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export interface VerifyMFATokenParamsDto {
  session: string;
  email: string;
  totp: string;
}

export interface AuthenticationResultTypeDto {
  AccessToken?: string;
  ExpiresIn?: number;
  TokenType?: string;
  RefreshToken?: string;
  IdToken?: string;
}

export interface NewPasswordChallengeResponseDto {
  /** @example SOFTWARE_TOKEN_MFA */
  challengeName:
    | 'ADMIN_NO_SRP_AUTH'
    | 'CUSTOM_CHALLENGE'
    | 'DEVICE_PASSWORD_VERIFIER'
    | 'DEVICE_SRP_AUTH'
    | 'MFA_SETUP'
    | 'NEW_PASSWORD_REQUIRED'
    | 'PASSWORD_VERIFIER'
    | 'SELECT_MFA_TYPE'
    | 'SMS_MFA'
    | 'SOFTWARE_TOKEN_MFA';
  authResult?: AuthenticationResultTypeDto;
  session?: string;
}

export interface NewPasswordChallangeBodyDto {
  email: string;
  password: string;
  session: string;
}

export interface ForgotPasswordConfirmParamsDto {
  email: string;
  password: string;
  code: string;
}

/**
 * @title PMU-Mediabank
 * @version 1.0
 * @contact
 *
 * Mediabank API description
 */
const http = createHttpApi({ baseURL: environment.apiUrl });

const api = {
  /**
   * No description
   *
   * @tags Health Checks
   * @name AppControllerHealthCheck
   * @request GET:/api/v1
   * @secure
   */
  appControllerHealthCheck: http.createRoute<void, string>({
    url: `/api/v1`,
    method: 'GET',
    responseType: 'json'
  }),

  users: {
    /**
     * No description
     *
     * @tags Users
     * @name GetUsers
     * @summary Get users list
     * @request GET:/api/v1/users
     * @secure
     */
    getUsers: http.createRoute<
      { limit?: string; nextToken?: string },
      { data: GetUsersListResponseDto; message?: string }
    >({
      url: `/api/v1/users`,
      method: 'GET',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUserByEmail
     * @summary Get User by Email
     * @request GET:/api/v1/users/{email}
     * @secure
     */
    getUserByEmail: http.createRoute<
      string,
      { data: GetUserByEmailResponseDto; message?: string }
    >(email => ({
      url: `/api/v1/users/${email}`,
      method: 'GET',
      responseType: 'json'
    })),

    /**
     * No description
     *
     * @tags Users
     * @name UpdateUserAttributes
     * @summary Update custom permission read/write attributes
     * @request PUT:/api/v1/users/{email}/attributes
     * @secure
     */
    updateUserAttributes: http.createRoute<
      { email: string; data: UpdateUserAttributesDto },
      void
    >(dto => ({
      url: `/api/v1/users/${dto.email}/attributes`,
      method: 'PUT',
      data: dto.data
    }))
  },
  usergroups: {
    /**
     * No description
     *
     * @tags Usergroups
     * @name GetUserGroupList
     * @summary Get usergroups list
     * @request GET:/api/v1/usergroups
     * @secure
     */
    getUserGroupList: http.createRoute<
      { limit?: string; nextToken?: string },
      { data: GetUsergroupListResponseDto; message?: string }
    >({
      url: `/api/v1/usergroups`,
      method: 'GET',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Usergroups
     * @name AddUserToGroup
     * @summary Add user to usergroup
     * @request POST:/api/v1/usergroups/add/user
     * @secure
     */
    addUserToGroup: http.createRoute<AddUserToGroupBodyDto, void>({
      url: `/api/v1/usergroups/add/user`,
      method: 'POST'
    }),

    /**
     * No description
     *
     * @tags Usergroups
     * @name RemoveUserFromGroup
     * @summary Remove user from specific usergroup
     * @request DELETE:/api/v1/usergroups/remove/user
     * @secure
     */
    removeUserFromGroup: http.createRoute<AddUserToGroupBodyDto, void>({
      url: `/api/v1/usergroups/remove/user`,
      method: 'DELETE'
    })
  },
  directories: {
    /**
     * No description
     *
     * @tags Directories
     * @name GetDirectories
     * @summary Get directories, with functions and markets
     * @request GET:/api/v1/directories
     * @secure
     */
    getDirectories: http.createRoute<
      void,
      { data: DirectoryDto; message?: string }
    >({
      url: `/api/v1/directories`,
      method: 'GET',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Directories
     * @name UpdateDirectories
     * @summary Update object metadata
     * @request PUT:/api/v1/directories
     * @secure
     */
    updateDirectories: http.createRoute<DirectoryDto, void>({
      url: `/api/v1/directories`,
      method: 'PUT'
    }),

    /**
     * No description
     *
     * @tags Directories
     * @name GetMarkets
     * @summary Get directories, with functions and markets
     * @request GET:/api/v1/directories/markets
     * @secure
     */
    getMarkets: http.createRoute<void, { data: MarketDto[]; message?: string }>(
      {
        url: `/api/v1/directories/markets`,
        method: 'GET',
        responseType: 'json'
      }
        ,{batchConcurrentRequests:true}),

    /**
     * No description
     *
     * @tags Directories
     * @name UpdateMarkets
     * @summary Update markets Directory
     * @request PUT:/api/v1/directories/markets
     * @secure
     */
    updateMarkets: http.createRoute<
      PutMarketsDto,
      { data: MarketDto[]; message?: string }
    >({
      url: `/api/v1/directories/markets`,
      method: 'PUT',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Directories
     * @name GetAllowedMarkets
     * @summary Get directories, with functions and markets
     * @request GET:/api/v1/directories/markets/allowed
     * @secure
     */
    getAllowedMarkets: http.createRoute<
      void,
      { data: MarketDto[]; message?: string }
    >({
      url: `/api/v1/directories/markets/read-allowed`,
      method: 'GET',
      responseType: 'json'
    },{batchConcurrentRequests:true}),


    /**
     * No description
     *
     * @tags Directories
     * @name GetAllowedMarkets
     * @summary Get directories, with functions and markets
     * @request GET:/api/v1/directories/markets/allowed
     * @secure
     */
    getAllowedWriteMarkets: http.createRoute<
      void,
      { data: MarketDto[]; message?: string }
    >({
      url: `/api/v1/directories/markets/write-allowed`,
      method: 'GET',
      responseType: 'json'
    },{batchConcurrentRequests:true}),

    /**
     * No description
     *
     * @tags Directories
     * @name GetFunctions
     * @summary Get directories, with functions and markets
     * @request GET:/api/v1/directories/functions
     * @secure
     */
    getFunctions: http.createRoute<
      void,
      { data: FunctionDto[]; message?: string }
    >({
      url: `/api/v1/directories/functions`,
      method: 'GET',
      responseType: 'json'
    },{batchConcurrentRequests:true}),

    /**
     * No description
     *
     * @tags Directories
     * @name UpdateFunctions
     * @summary Update markets Directory
     * @request PUT:/api/v1/directories/functions
     * @secure
     */
    updateFunctions: http.createRoute<
      PutFunctionsDto,
      { data: FunctionDto[]; message?: string }
    >({
      url: `/api/v1/directories/functions`,
      method: 'PUT',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Directories
     * @name GetAllowedFunctions
     * @summary Get directories, with functions and markets
     * @request GET:/api/v1/directories/functions/allowed
     * @secure
     */
    getAllowedFunctions: http.createRoute<
      void,
      { data: FunctionDto[]; message?: string }
    >({
      url: `/api/v1/directories/functions/read-allowed`,
      method: 'GET',
      responseType: 'json'
    },{batchConcurrentRequests:true}),

    /**
     * No description
     *
     * @tags Directories
     * @name GetAllowedFunctions
     * @summary Get directories, with functions and markets
     * @request GET:/api/v1/directories/functions/allowed
     * @secure
     */
    getAllowedWriteFunctions: http.createRoute<
      void,
      { data: FunctionDto[]; message?: string }
    >({
      url: `/api/v1/directories/functions/write-allowed`,
      method: 'GET',
      responseType: 'json'
    },{batchConcurrentRequests:true}),
  },
  files: {
    /**
     * No description
     *
     * @tags Files
     * @name UploadFile
     * @summary Upload file
     * @request POST:/api/v1/files/upload
     * @secure
     */
    uploadFile: http.createRoute<
      {
        market?: string;
        function?: string;
        service_year?: string;
        service_month?: string;
        subject?: string;
        from?:string;
        validation_date?: string;
        validated_by?: string;
        file?: File;
        description?: string;
      },
      { data: UploadResultDto; message?: string }
    >({
      url: `/api/v1/files/upload`,
      method: 'POST',
      type: ContentType.FormData,
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Files
     * @name DownloadFile
     * @summary Download file
     * @request POST:/api/v1/files/download
     * @secure
     */
    downloadFile: http.createRoute<DownloadFileDto, void>({
      url: `/api/v1/files/download`,
      method: 'POST',
      responseType:'blob'
    }),

    /**
     * No description
     *
     * @tags Files
     * @name UpdateFileMeta
     * @summary Update meta
     * @request PUT:/api/v1/files/meta
     * @secure
     */
    updateFileMeta: http.createRoute<UpdateFileMetaDto, void>({
      url: `/api/v1/files/meta`,
      method: 'PUT'
    }),

    /**
     * @description Deleting file allowed for Admins or for Owners of new files in 24 hours period
     *
     * @tags Files
     * @name DeleteFile
     * @summary Delete file
     * @request DELETE:/api/v1/files
     * @secure
     */
    deleteFile: http.createRoute<DeleteFileParamsDto, void>({
      url: `/api/v1/files`,
      method: 'DELETE'
    })
  },
  docs: {
    /**
     * No description
     *
     * @tags Docs
     * @name GetDocs
     * @summary Get all documents from S3
     * @request GET:/api/v1/docs/all
     * @secure
     */
    getDocs: http.createRoute<
      void,
      { data: DocsResponceDto[]; message?: string }
    >({
      url: `/api/v1/docs/all`,
      method: 'GET',
      responseType: 'json'
    })
  },
  auth: {
    /**
     * No description
     *
     * @tags Authorization
     * @name LoginInit
     * @summary Cognito init login
     * @request POST:/api/v1/auth/login/init
     */
    loginInit: http.createRoute<
      LoginInitParamsDto,
      { data: LoginInitResponseDto; message?: string }
    >({
      url: `/api/v1/auth/login/init`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization
     * @name LoginComplete
     * @summary Cognito complete login with totp
     * @request POST:/api/v1/auth/login/complete
     */
    loginComplete: http.createRoute<
      LoginCompleteParamsDto,
      { data: LoginCompleteResponseDto; message?: string }
    >({
      url: `/api/v1/auth/login/complete`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization
     * @name RefreshAccessTokens
     * @summary Cognito refresh token
     * @request POST:/api/v1/auth/refresh
     */
    refreshAccessTokens: http.createRoute<
      RefreshTokenDto,
      { data: LoginResponseDto; message?: string }
    >({
      url: `/api/v1/auth/refresh`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization
     * @name Logout
     * @summary Cognito log out (deactivates refreshToken)
     * @request POST:/api/v1/auth/logout
     * @secure
     */
    logout: http.createRoute<LogoutBodyDto, object>({
      url: `/api/v1/auth/logout`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization
     * @name GetUserInfo
     * @summary Get current user info
     * @request GET:/api/v1/auth/user
     * @secure
     */
    getUserInfo: http.createRoute<
      void,
      { data: GetUserInfoResponceDto; message?: string }
    >({
      url: `/api/v1/auth/user`,
      method: 'GET',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization
     * @name SignupInit
     * @summary Signup new user
     * @request POST:/api/v1/auth/signup
     */
    signupInit: http.createRoute<
      SignupBodyDto,
      { data: SignupResponseDto; message?: string }
    >({
      url: `/api/v1/auth/signup`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization Email
     * @name ConfirmEmail
     * @summary confirm email with email verification code
     * @request POST:/api/v1/auth/email/confirm
     */
    confirmEmail: http.createRoute<ConfirmEmailDto, void>({
      url: `/api/v1/auth/email/confirm`,
      method: 'POST'
    }),

    /**
     * No description
     *
     * @tags Authorization Email
     * @name ResendEmailCode
     * @summary Resend email confirmation code
     * @request POST:/api/v1/auth/email/resend
     */
    resendEmailCode: http.createRoute<ResendVerificationCodeDto, void>({
      url: `/api/v1/auth/email/resend`,
      method: 'POST'
    }),

    /**
     * No description
     *
     * @tags Authorization MFA
     * @name GetMfaToken
     * @summary Get qr image for use in Authentification apps, that generate MFA tokens (totp)
     * @request POST:/api/v1/auth/mfa/get-qr
     */
    getMfaToken: http.createRoute<
      AssociateMFATokenParamsDto,
      { data: AssociateMFATokenResponseDto; message?: string }
    >({
      url: `/api/v1/auth/mfa/get-qr`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization MFA
     * @name VerifyMfaToken
     * @summary Verify Authentification app with MFA token (totp)
     * @request POST:/api/v1/auth/mfa/qr-verify
     */
    verifyMfaToken: http.createRoute<
      VerifyMFATokenParamsDto,
      { data: VerifyMFATokenResponseDto; message?: string }
    >({
      url: `/api/v1/auth/mfa/qr-verify`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * @description Recreating user with same attributes and Usergroups. Sends temporary passwod to email
     *
     * @tags Authorization MFA
     * @name ResetMfa
     * @summary Reset Authentification app. Only for admins
     * @request POST:/api/v1/auth/mfa/reset/{email}
     * @secure
     */
    resetMfa: http.createRoute<string, void>(email => ({
      url: `/api/v1/auth/mfa/reset/${email}`,
      method: 'POST'
    })),

    /**
     * No description
     *
     * @tags Authorization-Passwords
     * @name ResetPassword
     * @summary Reset forgotten password for user
     * @request POST:/api/v1/auth/password/reset/{email}
     * @secure
     */
    resetPassword: http.createRoute<string, void>(email => ({
      url: `/api/v1/auth/password/reset/${email}`,
      method: 'POST'
    })),

    /**
     * No description
     *
     * @tags Authorization-Passwords
     * @name CompleteNewPasswordChallenge
     * @summary Complete NEW_PASSWORD_REQUIRED challenge
     * @request POST:/api/v1/auth/password/set
     */
    completeNewPasswordChallenge: http.createRoute<
      NewPasswordChallangeBodyDto,
      { data: NewPasswordChallengeResponseDto; message?: string }
    >({
      url: `/api/v1/auth/password/set`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * No description
     *
     * @tags Authorization-Passwords
     * @name ForgotPasswordConfirm
     * @summary Confirm of resetting password and set new ones
     * @request POST:/api/v1/auth/password/forgot/confirm
     */
    forgotPasswordConfirm: http.createRoute<
      ForgotPasswordConfirmParamsDto,
      object
    >({
      url: `/api/v1/auth/password/forgot/confirm`,
      method: 'POST',
      responseType: 'json'
    }),

    /**
     * @description Send secret code to email, next step is "auth/password/forgot/confirm"
     *
     * @tags Authorization-Passwords
     * @name ForgotPassword
     * @summary Reset forgotten password
     * @request POST:/api/v1/auth/password/forgot/{email}
     */
    forgotPassword: http.createRoute<string, object>(email => ({
      url: `/api/v1/auth/password/forgot/${email}`,
      method: 'POST',
      responseType: 'json'
    }))
  }
};

export { http, api };
