import { nanoid } from 'nanoid';

import { http } from '../api';

import type { MetaContract } from '../models';

const sharepoint = {
  checkFileExist: http.createRoute<string>(url => ({
    url: '/sharepoint/exist',
    method: 'POST',
    data: { url }
  })),

  getByUrls: http.createRoute<string[], MetaContract[]>(urls => ({
    url: '/sharepoint/',
    data: { urls }
  })),

  uploadFileMeta: http.createRoute<MetaContract>(data => ({
    url: '/sharepoint',
    method: 'POST',
    data
  }))
};

sharepoint.getByUrls.use(async (...params) => {
  const [, { data: dto }] = params as unknown as [
    unknown,
    { data: { urls: string[] } }
  ];

  return Promise.resolve(
    dto.urls.map((): MetaContract => {
      const id = nanoid();

      return {
        id,
        reviewId: id,
        type: 'pdf',
        subject: `File name ${id.slice(0, 3)}`,
        market: 'Market',
        project: 'Project',
        function: 'Function',
        from: 'From',
        service_year: '2022',
        service_month: '7',
        validated_by: 'ValidatedBy',
        validation_date: 'ValidationDate'
      };
    })
  );
});

sharepoint.checkFileExist.use(async () => {
  await new Promise<void>((resolve, reject) => {
    setTimeout(() => (Math.random() > 0.5 ? resolve() : reject()), 1500);
  });
});

sharepoint.uploadFileMeta.use(async () => {
  await new Promise<void>((resolve, reject) => {
    setTimeout(() => (Math.random() > 0.5 ? resolve() : reject()), 1500);
  });
});

export { sharepoint };
