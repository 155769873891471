import type { FileMeta } from '@entities/file';

import { typedObjectEntries } from '@shared/lib/prototype';

function getUnfulfilledMetas(metas: FileMeta[], skipValidationFields: boolean) {
  return metas.filter(
    field =>
      !typedObjectEntries(field).every(([key, value]) => {
        if (key === 'reviewId' || key === 'project') return true;

        const isSkippedValidation = skipValidationFields
          ? key === 'validationBy' || key === 'validationDate'
          : false;

        return isSkippedValidation || Boolean(value);
      })
  );
}

function getUnfulfilledKeys(meta: FileMeta, skipValidationFields: boolean) {
  return typedObjectEntries(meta)
    .filter(([key, value]) => {
      if (key === 'reviewId' || key === 'project') return false;

      const isSkippedValidation = skipValidationFields
        ? key === 'validationBy' || key === 'validationDate'
        : false;

      return !isSkippedValidation && !value;
    })
    .map(([key]) => key);
}

function isServiceDateValid({ serviceYear, serviceMonth }: FileMeta) {
  const currentYear = new Date().getFullYear().toString();

  if (serviceYear !== currentYear) return true;

  const currentMonth = new Date().getMonth() + 1;

  return +serviceMonth <= currentMonth;
}

function isDuplicateExplorerPath(metas: FileMeta[], meta: FileMeta) {
  const keys = [
    'market',
    'function',
    'serviceYear',
    'serviceMonth',
    'name',
    'type'
  ] as (keyof FileMeta)[];

  return metas.some(fileMeta => {
    if (fileMeta.id === meta.id) return false;

    return keys.every(
      key => fileMeta[key] === meta[key] && !!fileMeta[key] && !!meta[key]
    );
  });
}

function isStringValid(source: string) {
  const trimmed = source.trim();

  return !trimmed.match(/[*?\/\\|><₴,()[\]{};:'"~`!@#$%^&№А-Яа-яіІёЁ]/g);
}

function arrayConcatToString<T>(
  source: T[],
  getStringPart: (
    item: T,
    options: { isFirst: boolean; isLast: boolean; index: number }
  ) => string,
  base = ''
) {
  return source.reduce(
    (str, item, index) =>
      `${str}${getStringPart(item, {
        isFirst: index === 0,
        isLast: index === source.length - 1,
        index
      })}`,
    base
  );
}

export {
  isStringValid,
  getUnfulfilledMetas,
  getUnfulfilledKeys,
  isServiceDateValid,
  arrayConcatToString,
  isDuplicateExplorerPath
};
