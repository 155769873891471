type RequiredField<Source, Field extends keyof Source> = Pick<Source, Field> &
  Partial<Omit<Source, Field>>;

function patch<T extends { id: string }>(
  list: T[],
  item: RequiredField<T, 'id'>
): T[] {
  return list.map(link => (link.id === item.id ? { ...link, ...item } : link));
}

export { patch };
