import { ArrowRightAlt, Delete } from '@mui/icons-material';

import { LinearProgress, useTheme } from '@mui/material';

import {
  DataGrid,
  GridActionsCellItem,
  renderEditInputCell
} from '@mui/x-data-grid';

import type { GridEventListener } from '@mui/x-data-grid/models/events';

import type { ComponentType } from 'react';

import { createView } from '@shared/lib/view';

import type { ManageableDirectory } from './model';

type ManageDirectoryTableProps = {
  items: ManageableDirectory[];
  loading: boolean;
  temporaryExist: boolean;
  errorIds: ManageableDirectory['id'][];
  touchedIds: ManageableDirectory['id'][];
  onRowChange: (row: ManageableDirectory) => void;
  onDeleteClick: (row: ManageableDirectory['id']) => void;
  onDeleteExistingClick: (row: ManageableDirectory['id']) => void;
  onClearClick: (row: ManageableDirectory['id']) => void;

  editing: boolean;
  onCellEditStart?: GridEventListener<'cellEditStart'>;
  onCellEditStop?: GridEventListener<'cellEditStop'>;

  editable?: boolean;

  canDelete?: boolean;

  Footer: ComponentType;
};

const ManageDirectoryTable = createView<ManageDirectoryTableProps>().view(
  ({
    errorIds,
    touchedIds,
    items,
    loading,
    temporaryExist,
    onRowChange,
    onDeleteClick,
    onClearClick,
    onDeleteExistingClick,
    Footer,
    editable,

    canDelete,
    editing,
    onCellEditStop,
    onCellEditStart
  }) => {
    const theme = useTheme();

    return (
      <DataGrid
        rows={items}
        loading={loading}
        sx={{
          '& .error-cell': {
            outline: `1px solid ${theme.palette.error.main}`,
            outlineOffset: '-1px'
          },
          '& .name': { pl: 3 },
          '& .header-name': { pl: 3 }
        }}
        columns={
          temporaryExist
            ? [
                {
                  field: 'id',
                  headerName: 'ID',
                  type: 'string',
                  sortable: false,

                  align: 'center',
                  headerAlign: 'center',
                  width: 50
                },
                {
                  field: 'name',
                  headerName: 'Name',
                  type: 'string',
                  flex: 1,
                  editable: true,
                  sortable: false,
                  headerClassName: 'header-name',

                  renderEditCell: params =>
                    renderEditInputCell({ ...params, debounceMs: 0 }),
                  cellClassName: params =>
                    errorIds.includes(params.row.id) &&
                    touchedIds.includes(params.row.id)
                      ? 'error-cell'
                      : 'name'
                },
                {
                  type: 'actions',
                  field: 'action',
                  getActions: params =>
                    !params.row.temporary
                      ? []
                      : [
                          <GridActionsCellItem
                            key='clear'
                            label='Clear'
                            icon={
                              <ArrowRightAlt
                                style={{ transform: 'rotate(180deg)' }}
                              />
                            }
                            disabled={editing}
                            onClick={() => onClearClick(params.row.id)}
                          />,
                          <GridActionsCellItem
                            key='delete'
                            label='Delete'
                            icon={<Delete />}
                            disabled={editing}
                            onClick={() => onDeleteClick(params.row.id)}
                          />
                        ]
                }
              ]
            : [
                {
                  field: 'id',
                  headerName: 'ID',
                  type: 'string',
                  sortable: false,

                  align: 'center',
                  headerAlign: 'center',
                  width: 50,
                  editable
                },
                {
                  field: 'name',
                  headerName: 'Name',
                  type: 'string',
                  flex: 1,
                  editable,
                  sortable: false,
                  headerClassName: 'header-name',
                  cellClassName: params =>
                    errorIds.includes(params.row.id) &&
                    touchedIds.includes(params.row.id)
                      ? 'error-cell'
                      : 'name'
                },
                {
                  type: 'actions',
                  field: 'action',
                  getActions: params => {
                    if (!canDelete) return [];

                    return [
                      <GridActionsCellItem
                        key='delete'
                        label='Delete'
                        icon={<Delete />}
                        disabled={editing || loading}
                        onClick={() => onDeleteExistingClick(params.row.id)}
                      />
                    ];
                  }
                }
              ]
        }
        disableColumnMenu
        disableColumnFilter
        disableVirtualization
        disableSelectionOnClick
        processRowUpdate={row => {
          onRowChange(row);

          return row;
        }}
        onCellEditStop={onCellEditStop}
        onCellEditStart={onCellEditStart}
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          Footer: Footer,
          LoadingOverlay: LinearProgress
        }}
      />
    );
  }
);

export { ManageDirectoryTable };
