import { fileInputChanged, fileInputErrored } from './model';

const model = { fileInputChanged, fileInputErrored };

export { model as uploadFileModel };

export * from './config';

export { UploadFile } from './ui';

export { UploadFilesLocalAdapter } from './lib';
