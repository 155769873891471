import { ManageFileMetaGrid } from '@features/manage-file-meta';

import { createView } from '@shared/lib/view';

import { manageFileMetaModel } from './model';

const ManageGridStep = createView()
  .open(manageFileMetaModel.page.mounted)
  .close(manageFileMetaModel.page.unmounted)
  .view(() => (
    <ManageFileMetaGrid
      model={manageFileMetaModel}
      hideFooter
      removeDelete
      columnVisibility={{ reviewId: false }}
    />
  ));

export { ManageGridStep };
