import { Delete as DeleteIcon } from '@mui/icons-material';

import { LinearProgress, Tooltip, useTheme } from '@mui/material';

import type { GridActionsColDef, GridColDef } from '@mui/x-data-grid';

import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer
} from '@mui/x-data-grid';

import { useEvent, useStore } from 'effector-react';

import React from 'react';

import { $$directory } from '@entities/directories';

import type { FileMeta } from '@entities/file';

import { createView } from '@shared/lib/view';

import { DataTableFooter, renderEditMultipleSelectCell } from '@shared/ui';

import {
  fieldHeaderNameMap,
  lastValidYearServiceMonths,
  serviceMonths,
  serviceMonthsMap,
  serviceYears
} from './config';

import type { createManageFileMeta } from './model';

type ManageFileMetaGridProps = NonModelProps & {
  model: ReturnType<typeof createManageFileMeta>;
};

type ManageFileMetaGridInternalProps = ManageFileMetaGridFooterProps &
  NonModelProps & {
    items: FileMeta[];

    touched: boolean;

    unfulfilledMetasMap: Record<
      FileMeta['id'],
      { meta: FileMeta; fields: (keyof FileMeta)[] }
    >;
    serviceMonthErrorMap: Record<FileMeta['id'], FileMeta>;
    duplicateExplorerPathRowsMap: Record<FileMeta['id'], FileMeta>;
    invalidFromMetasMap: Record<FileMeta['id'], FileMeta>;
    invalidFileNameMetasMap: Record<FileMeta['id'], FileMeta>;

    onRowChange: (changed: FileMeta) => void;
    onRowDelete: (id: FileMeta['id']) => void;

    onEditStart: () => void;
    onEditFinish: () => void;
  };

type NonModelProps = {
  removeDelete?: boolean;
  hideFooter?: boolean;

  columnVisibility?: Partial<Record<keyof FileMeta, boolean>>;
};

type ManageFileMetaGridFooterProps = {
  isDirty: boolean;
  editing: boolean;
  loading: boolean;
  onReset: () => void;
  onSubmit: () => void;
};

const ManageFileMetaGridProvider = createView<ManageFileMetaGridProps>()
  .map(({ model }) => {
    const items = useStore(model.$metas);
    const loading = useStore(model.$submitting);
    const isDirty = useStore(model.$metasExist);
    const touched = useStore(model.$touched);
    const editing = useStore(model.$editing);
    const unfulfilledMetasMap = useStore(model.$unfulfilledMetaMap);
    const invalidFileNameMetasMap = useStore(model.$invalidFileNameMetasMap);
    const serviceMonthErrorMap = useStore(model.$serviceMonthErrorMap);
    const duplicateExplorerPathRowsMap = useStore(
      model.$duplicateExplorerPathRowsMap
    );

    const invalidFromMetasMap = useStore(model.$invalidFromMetasMap);

    const [
      onRowChange,
      onRowDelete,
      onSubmit,
      onReset,
      onEditStart,
      onEditFinish
    ] = useEvent([
      model.metaChanged,
      model.deleteClicked,
      model.submitClicked,
      model.resetClicked,
      model.editStarted,
      model.editFinished
    ]);

    return {
      items,
      editing,
      loading,
      isDirty,
      touched,
      invalidFromMetasMap,
      unfulfilledMetasMap,
      serviceMonthErrorMap,
      invalidFileNameMetasMap,
      duplicateExplorerPathRowsMap,
      onRowChange,
      onRowDelete,
      onEditStart,
      onEditFinish,
      onSubmit,
      onReset
    };
  })

  .view(({ model, ...props }) => <ManageFileMetaGrid {...props} />);

const ManageFileMetaGrid = createView<ManageFileMetaGridInternalProps>()
  .units({
    markets: $$directory.$allowedMarketNames,
    functions: $$directory.$allowedFunctionNames
  })
  .defaultProps({
    columnVisibility: {
      reviewId: false,
      validationBy: false,
      validationDate: false
    }
  })

  .view(
    ({
      items,
      editing,
      loading,
      markets,
      functions,
      touched,
      invalidFromMetasMap,
      unfulfilledMetasMap,
      serviceMonthErrorMap,
      invalidFileNameMetasMap,
      duplicateExplorerPathRowsMap,
      onRowChange,
      onRowDelete,

      removeDelete,
      hideFooter,

      columnVisibility,
      //footer props
      onReset,
      onSubmit,
      onEditFinish,
      onEditStart,
      isDirty
    }) => {
      const theme = useTheme();

      const baseColumns: GridColDef<FileMeta>[] = (
        [
          {
            field: 'reviewId',
            headerName: fieldHeaderNameMap.reviewId,
            type: 'string',
            flex: 1
          },
          {
            field: 'name',
            headerName: fieldHeaderNameMap.name,
            type: 'string',
            flex: 3,
            editable: true
          },
          {
            field: 'type',
            headerName: fieldHeaderNameMap.type,
            type: 'string',
            flex: 1
          },
          {
            field: 'from',
            headerName: fieldHeaderNameMap.from,
            type: 'string',
            flex: 1,
            editable: true
          },
          {
            field: 'market',
            headerName: fieldHeaderNameMap.market,
            type: 'singleSelect',
            valueOptions: markets,
            renderEditCell: renderEditMultipleSelectCell,
            valueFormatter: params =>
              Array.isArray(params.value)
                ? params.value.join(', ')
                : params.value ?? '',
            flex: 1.75,
            editable: true
          },
          {
            field: 'function',
            headerName: fieldHeaderNameMap.function,
            type: 'singleSelect',
            valueOptions: functions,
            flex: 1,
            editable: true
          },
          {
            field: 'project',
            headerName: fieldHeaderNameMap.project,
            type: 'string',
            flex: 1.25,
            editable: true
          },
          {
            field: 'serviceYear',
            headerName: fieldHeaderNameMap.serviceYear,
            type: 'singleSelect',
            valueOptions: serviceYears,
            flex: 1,
            editable: true
          },
          {
            field: 'serviceMonth',
            headerName: fieldHeaderNameMap.serviceMonth,
            type: 'singleSelect',
            valueFormatter: params =>
              serviceMonthsMap[params.value]?.label ?? params.value,
            valueOptions: params => {
              const metaId = params?.row?.id;

              if (!metaId) return serviceMonths;

              return serviceMonthErrorMap[metaId]
                ? lastValidYearServiceMonths
                : serviceMonths;
            },
            flex: 1,
            editable: true
          },
          {
            field: 'validationBy',
            headerName: fieldHeaderNameMap.validationBy,
            type: 'string',
            flex: 1,
            sortable: false
          },
          {
            field: 'validationDate',
            headerName: fieldHeaderNameMap.validationDate,
            type: 'string',
            flex: 1
          }
        ] as GridColDef<FileMeta>[]
      ).map(cellDef => ({
        ...cellDef,
        sortable: false,
        renderCell: params => (
          <Tooltip title={params.formattedValue} placement='bottom-start'>
            <span>{params.formattedValue}</span>
          </Tooltip>
        )
      }));

      const actionColumn: GridActionsColDef<FileMeta> = {
        field: 'ACTIONS',
        type: 'actions',
        getActions: params => [
          <GridActionsCellItem
            key='delete'
            icon={<DeleteIcon />}
            label='Delete'
            onClick={() => onRowDelete(params.row.id)}
          />
        ]
      };

      return (
        <DataGrid<FileMeta>
          rows={items}
          loading={loading}
          editMode='row'
          sx={{
            '& .error-cell': {
              outline: `1px solid ${theme.palette.error.main}`,
              outlineOffset: '-1px'
            }
          }}
          columns={removeDelete ? baseColumns : [...baseColumns, actionColumn]}
          processRowUpdate={row => {
            onRowChange(row);

            return row;
          }}
          getCellClassName={params => {
            if (!touched) return '';

            const metaId = params.row.id;
            const row = unfulfilledMetasMap[metaId];
            const field = params.field as keyof FileMeta;

            if (field === 'from') {
              if (invalidFromMetasMap[metaId]) return 'error-cell';
            }

            if (field === 'serviceMonth') {
              if (serviceMonthErrorMap[metaId]) return 'error-cell';
            }

            if (field === 'name') {
              if (
                duplicateExplorerPathRowsMap[metaId] ||
                invalidFileNameMetasMap[metaId]
              )
                return 'error-cell';
            }

            const isRowFulfilled = !row || !row.fields.includes(field);

            return isRowFulfilled ? '' : 'error-cell';
          }}
          hideFooter={hideFooter}
          disableColumnMenu
          disableColumnFilter
          disableVirtualization
          disableSelectionOnClick
          onRowEditStart={onEditStart}
          onRowEditStop={onEditFinish}
          experimentalFeatures={{ newEditingApi: true }}
          components={{ Footer, LoadingOverlay: LinearProgress, Toolbar }}
          initialState={{
            columns: { columnVisibilityModel: columnVisibility }
          }}
          componentsProps={{
            footer: {
              onReset,
              onSubmit,
              isDirty,
              loading,
              editing
            } as ManageFileMetaGridFooterProps
          }}
        />
      );
    }
  );

const Footer = createView<ManageFileMetaGridFooterProps>().view(
  ({ isDirty, loading, editing, onReset, onSubmit }) => {
    if (!isDirty) return null;

    return (
      <DataTableFooter
        isSubmitting={loading}
        onSubmit={onSubmit}
        onReset={onReset}
        width={200}
        resetText='Clear'
        submitText='Upload to bucket'
        disabledSubmit={editing}
      />
    );
  }
);

const Toolbar = createView().view(() => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
  </GridToolbarContainer>
));

export {
  ManageFileMetaGrid as ManageFileMetaGridInternal,
  ManageFileMetaGridProvider as ManageFileMetaGrid
};

export type { ManageFileMetaGridProps, ManageFileMetaGridInternalProps };
