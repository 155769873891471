import type { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';

import type { KeyboardEvent } from 'react';

import { useMemo } from 'react';

import { Field } from '@shared/form';

import { createView } from '@shared/lib/view';

import type { TextProps } from '../atoms/text';

type NumberProps = TextProps & {
  maxLength?: number;
  positive?: boolean;
};

const Number = createView<NumberProps>()
  .map(({ maxLength, positive, inputProps }) => {
    const overriddenInputProps = useMemo(() => {
      const props: InputBaseComponentProps = {
        ...inputProps,
        pattern: '[0-9]*'
      };

      if (maxLength) {
        props.maxLength = maxLength;
        props.max = lengthToMaxNumber(maxLength);
      }

      if (positive) {
        props.min = 0;
      }

      return props;
    }, [maxLength, positive, inputProps]);

    return { overriddenInputProps };
  })
  .view(
    ({ maxLength, positive, inputProps, overriddenInputProps, ...props }) => (
      <Field.Text
        type='number'
        onKeyDown={e => {
          if (isForbiddenNumberPressed(e)) {
            e.preventDefault();

            return;
          }

          props.onKeyDown?.(e);
        }}
        inputProps={overriddenInputProps}
        {...props}
      />
    )
  );

function lengthToMaxNumber(maxLength: number): number {
  return +Array.from({ length: maxLength }, () => '9').join('');
}

function isForbiddenNumberPressed<T>(e: KeyboardEvent<T>) {
  return ['e', '+', '-', ',', '.'].includes(e.key.toLowerCase());
}

export type { NumberProps };

export { Number };
