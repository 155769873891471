import { createEvent, sample } from 'effector';

import { sessionLib } from '@entities/session';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { loginFx } from './api';

import { isNotAuthorizedError } from './lib';

const reset = createEvent();

const form = createForm({
  fields: {
    email: {
      init: '',

      rules: [sessionLib.validation.email]
    },

    password: {
      init: '',

      rules: [sessionLib.validation.password]
    }
  },

  resetOn: reset,

  blurValidatePreset: true
});

//submit

sample({
  clock: form.formValidated,
  target: loginFx
});

//submit failed
notification({
  clock: sample({
    clock: loginFx.failData,
    filter: isNotAuthorizedError
  }),

  message: 'Invalid credentials',

  mode: 'error',

  http: true
});

export { loginFx, form, reset };
