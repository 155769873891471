import { createEvent, createStore, sample } from 'effector';

import { $$session, sessionLib } from '@entities/session';

import { group } from '@shared/lib/effector-group';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { completeLoginFx } from './api';

import {
  isCodeMismatchError,
  isExpiredCodeError,
  isNotAuthorizedError
} from './lib';

const dataPopulated = createEvent<{ email: string; session: string }>();
const reset = createEvent();

const $session = createStore('');
const $email = createStore('');

const form = createForm({
  fields: {
    code: {
      init: '',
      rules: [sessionLib.validation.code],
      filter: sessionLib.canEnterEmailCode
    }
  },

  blurValidatePreset: true,
  resetOn: reset
});

group('preload', () => {
  $session.on(dataPopulated, (_, { session }) => session);
  $email.on(dataPopulated, (_, { email }) => email);
});

group('submit', () => {
  sample({
    clock: form.formValidated,
    source: { session: $session, email: $email },
    fn: ({ session, email }, { code }) => ({ session, email, totp: code }),
    target: completeLoginFx
  });

  notification({
    clock: completeLoginFx.failData,

    message: error =>
      isExpiredCodeError(error)
        ? 'Your verification code has already been used once'
        : isCodeMismatchError(error)
        ? 'Invalid verification code'
        : isNotAuthorizedError(error)
        ? 'Session is expired. Try login again'
        : 'Verify error',

    mode: 'error',

    http: true
  });

  sample({
    clock: completeLoginFx.done,
    target: $$session.meFx
  });
});

export { form, reset, completeLoginFx, dataPopulated };
