import { attach, createEvent, createStore } from 'effector';

import { api, apiLocal } from '@shared/api';

import type { LocalFile, LocalFileId } from './types';

const uploadLocalFileFx = attach({
  mapParams: (
    params: Required<{
      market?: string;
      function?: string;
      service_year?: string;
      service_month?: string;
      subject?: string;
      from?: string;
      validation_date?: string;
      validated_by?: string;
      file?: File;
      description?: string;
      id: string;
    }>
  ) => params,
  effect: api.files.uploadFile
});

const getSPFilesMetaByUrlsFx = attach({
  effect: apiLocal.sharepoint.getByUrls
});
const uploadSPFileMetaFx = attach({
  effect: apiLocal.sharepoint.uploadFileMeta
});
const checkFileExistInSPFx = attach({
  effect: apiLocal.sharepoint.checkFileExist
});

const localFilesUploaded = createEvent<LocalFile[]>();
const localFileDeleted = createEvent<LocalFileId>();
const localFilesCleared = createEvent<LocalFileId[] | void>();

const $localFiles = createStore<LocalFile[]>([])
  .on(localFilesUploaded, (oldFiles, newFiles) => [...oldFiles, ...newFiles])
  .on(localFileDeleted, (files, fileId) =>
    files.filter(file => file.id !== fileId)
  )
  .on(uploadLocalFileFx.done, (files, { params }) =>
    files.filter(file => file.id !== params.subject)
  )
  .on(localFilesCleared, (files, payload) => {
    if (!payload) return [];

    return files.filter(file => !payload.includes(file.id));
  });

export {
  $localFiles,
  localFilesUploaded,
  localFileDeleted,
  localFilesCleared,
  uploadLocalFileFx,
  checkFileExistInSPFx,
  getSPFilesMetaByUrlsFx,
  uploadSPFileMetaFx
};
