import type { GridCellParams, GridColumns } from '@mui/x-data-grid';

import { fieldHeaderNameMap } from '@features/manage-file-meta/config';

import type { BreadcrumbsLevel, DocumentInfo } from './types';

const breadcrumbsLevels: BreadcrumbsLevel[] = [
  'root',
  'market',
  'function',
  'year',
  'month'
];

const resetMarketLevels = breadcrumbsLevels.slice(0, 1);
const resetProjectLevels = breadcrumbsLevels.slice(0, 2);
const resetYearLevels = breadcrumbsLevels.slice(0, 3);
const resetMonthLevels = breadcrumbsLevels.slice(0, 4);

const fileMetaColumns: GridColumns<DocumentInfo> = [
  {
    field: 'reviewId',
    headerName: fieldHeaderNameMap.reviewId,
    flex: 1,
    minWidth: 100
  },
  {
    field: 'name',
    headerName: fieldHeaderNameMap.name,
    flex: 3,
    minWidth: 300
  },
  {
    field: 'type',
    headerName: fieldHeaderNameMap.type,
    flex: 1,
    minWidth: 75
  },
  {
    field: 'from',
    headerName: fieldHeaderNameMap.from,
    flex: 2,
    minWidth: 200
  },
  {
    field: 'market',
    headerName: fieldHeaderNameMap.market,
    flex: 1,
    minWidth: 125
  },
  {
    field: 'function',
    headerName: fieldHeaderNameMap.function,
    flex: 1,
    minWidth: 200
  },
  {
    field: 'project',
    headerName: fieldHeaderNameMap.project,
    flex: 1.25,
    minWidth: 125
  },
  {
    field: 'serviceYear',
    type: 'string',
    headerName: fieldHeaderNameMap.serviceYear,
    flex: 1,
    minWidth: 110,

    getApplyQuickFilterFn: getGridStringQuickFilterFn
  },
  {
    field: 'serviceMonth',
    headerName: fieldHeaderNameMap.serviceMonth,
    flex: 1,
    minWidth: 125,

    getApplyQuickFilterFn: getGridStringQuickFilterFn
  },

  {
    field: 'validationBy',
    type: 'string',
    headerName: fieldHeaderNameMap.validationBy,
    flex: 1,
    minWidth: 110
  },
  {
    field: 'validationDate',
    type: 'string',
    headerName: fieldHeaderNameMap.validationDate,
    flex: 1,
    minWidth: 125
  }
];

function getGridStringQuickFilterFn(value: string) {
  if (!value) return null;

  return ({ value: columnValue, formattedValue }: GridCellParams): boolean =>
    [formattedValue, columnValue].some(_value =>
      (_value ?? '')
        .toString()
        .toUpperCase()
        .includes(value.toString().toUpperCase())
    );
}

export {
  fileMetaColumns,
  breadcrumbsLevels,
  resetMonthLevels,
  resetYearLevels,
  resetMarketLevels,
  resetProjectLevels
};
