import { v4 } from 'uuid';

import { serviceMonthsMap } from '@features/manage-file-meta/config';

import type { DirectoryDto, GetDocsDto } from '@shared/api';

import type { DocumentInfo, FileMetaExplorerMap } from './types';

class DocAdapter {
  public static from(
    { Key, Metadata, relativePath }: GetDocsDto['data'][number],
    directory: DirectoryDto,
    validatedBy: string,
    isAdmin = false
  ): DocumentInfo {
    const market = Metadata.market
      .split(',')
      .map(id => id.trim())
      .map(id => directory.market.find(market => market.id === id)?.name)
      .filter(Boolean)
      .join(', ');

    const formattedFunction =
      directory.function.find(({ id }) => id === Metadata.function)?.name ??
      '—';

    const isAuthor = validatedBy === Metadata.validated_by;
    const now = Date.now();
    const from = new Date(Metadata.validation_date).getTime();
    const DAY = 24 * 60 * 60 * 1000;
    const dayAgo = now - DAY;
    const canDeletedByAuthor = from >= dayAgo;

    return {
      id: v4(),

      reviewId: Metadata.sharepoint_id ?? '',

      storageKey: Key,

      from: Metadata.from,
      market: market,
      type: Metadata.type,
      project: Metadata.project ?? Metadata.description,
      function: formattedFunction,
      name: Metadata.subject,
      serviceMonth: serviceMonthsMap[Metadata.service_month].label,
      serviceYear: Metadata.service_year,
      validationBy: Metadata.validated_by,
      validationDate: new Date(Metadata.validation_date).toLocaleDateString(),
      canDelete: isAdmin || (isAuthor && canDeletedByAuthor),

      relativePath
    };
  }

  public static toExplorerMap(data: DocumentInfo[]): FileMetaExplorerMap {
    const mappedRelativePaths = {} as FileMetaExplorerMap;

    data.forEach(fileMeta => {
      const [rawMarket, project, year, month] =
        fileMeta.relativePath.split('/');

      const market = rawMarket === 'unknown' ? 'Common' : rawMarket;

      if (!mappedRelativePaths[market]) {
        mappedRelativePaths[market] = {};
      }

      if (!mappedRelativePaths[market][project]) {
        mappedRelativePaths[market][project] = {};
      }

      if (!mappedRelativePaths[market][project][year]) {
        mappedRelativePaths[market][project][year] = {};
      }

      if (!mappedRelativePaths[market][project][year][month]) {
        mappedRelativePaths[market][project][year][month] = [];
      }

      mappedRelativePaths[market][project][year][month].push(fileMeta);
    });

    return mappedRelativePaths;
  }
}

export { DocAdapter };
