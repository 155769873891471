import { addOrUpdate } from './add-or-update';

import { append } from './append';

import { patch } from './patch';

import { put } from './put';

import { remove } from './remove';

const repository = {
  append,
  patch,
  put,
  addOrUpdate,
  remove
};

export { repository };
