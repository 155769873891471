import { createEvent, sample } from 'effector';

import { sessionLib } from '@entities/session';

import type { SignupBodyDto } from '@shared/api';

import { group } from '@shared/lib/effector-group';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { signUpFx } from './api';

import { isUserAlreadyExistError, isUserNotConfirmedError } from './lib';

const reset = createEvent();

const form = createForm<SignupBodyDto>({
  fields: {
    email: {
      init: '',

      rules: [sessionLib.validation.email]
    },
    firstName: {
      init: '',

      rules: [sessionLib.validation.firstName]
    },
    lastName: {
      init: '',

      rules: [sessionLib.validation.lastName]
    },
    password: {
      init: '',

      rules: [sessionLib.validation.password]
    }
  },

  resetOn: reset,

  blurValidatePreset: true
});

group('submit', () => {
  sample({
    clock: form.formValidated,
    target: signUpFx
  });

  notification({
    clock: signUpFx.failData,

    message: error =>
      isUserAlreadyExistError(error)
        ? 'Account with this email already exists'
        : isUserNotConfirmedError(error)
        ? 'Account with this email already exists. Confirm email to continue'
        : 'Error while register. Use different credentials',

    mode: 'error',

    http: true
  });

  notification({
    clock: signUpFx.done,

    message: 'Successfully registered',

    mode: 'success'
  });
});

export { signUpFx, form, reset };
