import { SnackbarProvider } from 'notistack';

import type { FC, ReactNode } from 'react';

import { NotificationStack } from '@shared/notification';

import { WithRouter } from './router';

import { ThemeProvider } from './theme';

const Provider: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeProvider>
    <SnackbarProvider maxSnack={8} autoHideDuration={3000}>
      <NotificationStack />

      <WithRouter>{children}</WithRouter>
    </SnackbarProvider>
  </ThemeProvider>
);

export { Provider };
