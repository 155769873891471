import { attach, combine, createEvent, createStore, sample } from 'effector';

import { reset } from 'patronum';

import { fileModel } from '@entities/file';

import { hashMap } from '@shared/lib/hash-map';

import { repository } from '@shared/lib/repository';

import { createPage, validation } from '@shared/lib/units';

import { errorMap, errorTypeMessageMap } from './config';

import {
  createLink,
  isDuplicatedLink,
  isEmpty,
  isNotEmpty,
  isSingleItem,
  isSuccessStatus,
  isUnique,
  isUrl
} from './lib';

import type { LinkError, LinkStatus, SharePointLink } from './types';

const checkExistFileInSharepointFx = attach({
  mapParams: (params: SharePointLink) => params.url,
  effect: fileModel.checkFileExistInSPFx
});

const page = createPage();

const resetClicked = createEvent();
const addLinkClicked = createEvent();
const removeLinkClicked = createEvent<SharePointLink>();

const linkChanged = createEvent<SharePointLink>();

const $links = createStore<SharePointLink[]>([]);
const $statuses = createStore<LinkStatus[]>([]);
const $errors = createStore<LinkError[]>([]);

//mounted
const linksInitiated = sample({
  clock: page.mounted,
  source: $links.map(isEmpty),
  fn: () => createLink()
});

$links.on(linksInitiated, (_, link) => [link]);
$statuses.on(linksInitiated, (_, { id }) => [{ id, status: 'initial' }]);

//change
$links.on(linkChanged, (links, changed) => repository.patch(links, changed));

const isUrlValidation = validation({
  clock: linkChanged,
  validate: ({ url }) => isUrl(url)
});

$statuses.on(isUrlValidation.failed, (links, { id }) =>
  repository.patch(links, { id, status: 'error' })
);

$errors.on(isUrlValidation.failed, (links, { id }) =>
  repository.addOrUpdate(links, { id, type: errorMap.INVALID_URL })
);

const uniqueValidation = validation({
  clock: sample({
    clock: isUrlValidation.passed,
    source: $links,
    fn: (links, link) => ({ link, isUnique: isUnique(links, link) })
  }),
  validate: ({ isUnique }) => isUnique
});

$statuses.on(uniqueValidation.failed, (links, { link: { id } }) =>
  repository.patch(links, { id, status: 'error' })
);

$errors.on(uniqueValidation.failed, (links, { link: { id } }) =>
  repository.addOrUpdate(links, { id, type: errorMap.DUPLICATE })
);

sample({
  clock: uniqueValidation.passed,
  fn: ({ link }) => link,
  target: checkExistFileInSharepointFx
});

$statuses
  .on(checkExistFileInSharepointFx, (links, { id }) =>
    repository.patch(links, { id, status: 'pending' })
  )
  .on(checkExistFileInSharepointFx.done, (links, { params: { id } }) =>
    repository.patch(links, { id, status: 'success' })
  )
  .on(checkExistFileInSharepointFx.fail, (links, { params: { id } }) =>
    repository.patch(links, { id, status: 'error' })
  );

$errors
  .on(checkExistFileInSharepointFx.done, (links, { params }) =>
    repository.remove(links, params.id)
  )
  .on(checkExistFileInSharepointFx.fail, (links, { params: { id } }) =>
    repository.addOrUpdate(links, { id, type: errorMap.NOT_FOUND })
  );

//add
const linkCreated = sample({
  clock: addLinkClicked,
  fn: () => createLink()
});

$links.on(linkCreated, (links, link) => repository.append(links, link));
$statuses.on(linkCreated, (statuses, { id }) =>
  repository.append(statuses, { id, status: 'initial' })
);

//remove
const enrichedRemoveLinkClicked = sample({
  clock: removeLinkClicked,
  source: $links,
  fn: (links, removedLink) => ({
    link: removedLink,
    duplicated: links.filter(link => isDuplicatedLink(link, removedLink))
  })
});

$links.on(enrichedRemoveLinkClicked, (links, { link }) =>
  repository.remove(links, link.id)
);
$statuses.on(enrichedRemoveLinkClicked, (links, { link }) =>
  repository.remove(links, link.id)
);
$errors.on(enrichedRemoveLinkClicked, (links, { link }) =>
  repository.remove(links, link.id)
);

sample({
  clock: enrichedRemoveLinkClicked,
  filter: ({ duplicated }) => isNotEmpty(duplicated),
  fn: ({ duplicated }) => ({ link: duplicated[0], isUnique: true }),
  target: uniqueValidation.passed
});

//reset clicked

reset({
  clock: resetClicked,
  target: [$errors, $links, $statuses]
});

//selectors
const $errorMap = $errors.map(errors =>
  hashMap(errors, ({ type }) => errorTypeMessageMap[type])
);

const $statusMap = $statuses.map(errors =>
  hashMap(errors, ({ status }) => status)
);

const $rows = combine($links, $statusMap, (links, statuses) =>
  links.map(link => ({ ...link, status: statuses[link.id] }))
);

const $onlyOneRowExist = $rows.map(isSingleItem);

const $isValid = $statuses.map(links => links.every(isSuccessStatus));

export {
  $rows,
  $links,
  $isValid,
  $errorMap,
  $onlyOneRowExist,
  page,
  linkChanged,
  resetClicked,
  addLinkClicked,
  removeLinkClicked
};
