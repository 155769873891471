import { createEvent } from 'effector';

const createEffectResult = <Done, Fail = Done>() => {
  const done = createEvent<Done>();
  const fail = createEvent<Fail>();

  return { done, fail };
};

export { createEffectResult };
