import { nanoid } from 'nanoid';

import type { LinkStatus, SharePointLink } from './types';

function createLink(url = 'https://pmu.sharepoint.com'): SharePointLink {
  return {
    id: nanoid(),
    url
  };
}

function getUrl({ url }: SharePointLink) {
  return url;
}

function getUrls(urls: SharePointLink[]) {
  return urls.map(getUrl);
}

function isUrl(value: string) {
  return value.trim().startsWith('https://pmu.sharepoint.com');
}

function isEmpty<T>(list: T[]) {
  return list.length === 0;
}

function isNotEmpty<T>(list: T[]) {
  return list.length > 0;
}

function isSingleItem<T>(list: T[]) {
  return list.length === 1;
}

function isSuccessStatus({ status }: LinkStatus) {
  return status === 'success';
}

function isUnique(source: SharePointLink[], target: SharePointLink) {
  const urls = source.map(getUrl);
  const isUrlsUnique = [...new Set(urls)].length === urls.length;

  if (isUrlsUnique) return true;

  return !source.some(({ url, id }) => target.id !== id && target.url === url);
}

function isDuplicatedLink(source: SharePointLink, target: SharePointLink) {
  return source.url === target.url && source.id !== target.id;
}

export {
  isUrl,
  getUrl,
  getUrls,
  isEmpty,
  isUnique,
  createLink,
  isNotEmpty,
  isSingleItem,
  isSuccessStatus,
  isDuplicatedLink
};
