import { Visibility, VisibilityOff } from '@mui/icons-material';

import { IconButton } from '@mui/material';

import { useFieldError } from '@shared/form/hooks';

import { useOpen } from '@shared/lib/hooks';

import { createView } from '@shared/lib/view';

import type { TextProps } from '../atoms/text';

import { Text } from '../atoms/text';

type PasswordProps = TextProps;

const Password = createView<PasswordProps>()
  .map(({ field }) => {
    const { isOpen: isVisible, toggle: onToggleVisible } = useOpen();

    const { isError } = useFieldError(field);

    return { isVisible, isError, onToggleVisible };
  })
  .view(({ isVisible, isError, onToggleVisible, ...props }) => (
    <Text
      type={isVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label='toggle password visibility'
            onClick={onToggleVisible}
            color={isError ? 'error' : 'default'}
          >
            {isVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        )
      }}
      {...props}
    />
  ));

export { Password };

export type { PasswordProps };
