type LoginRoutes = {
  (): string;
  mfa: () => string;
  changePassword: () => string;
};

const loginRoutes: LoginRoutes = () => '/login';
loginRoutes.mfa = () => '/login/mfa';
loginRoutes.changePassword = () => '/login/change-password';

export { loginRoutes };
