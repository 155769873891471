import {
  attach,
  combine,
  createDomain,
  createEvent,
  merge,
  sample
} from 'effector';

import { pending, reset } from 'patronum';

import { $$directory } from '@entities/directories';

import { $$session } from '@entities/session';

import type { FunctionDto, MarketDto } from '@shared/api';

import { group } from '@shared/lib/effector-group';

import { emptyArray } from '@shared/lib/prototype';

import { createPage, validation } from '@shared/lib/units';

import { notification } from '@shared/notification';

type DirectoryType = 'function' | 'market';
type BaseDirectory = FunctionDto | MarketDto;
type ManageableDirectory = BaseDirectory & { temporary: boolean };

const createManageDirectoryModel = (type: DirectoryType) => {
  const isMarket = type === 'market';

  const getDirectoriesFx = attach({ effect: $$directory.getDirectoriesFx });

  const updateDirectoryFx = attach({
    source: isMarket ? $$directory.$functions : $$directory.$markets,
    mapParams: (payload: BaseDirectory[], source) =>
      isMarket
        ? {
            market: payload,
            function: source
          }
        : { market: source, function: payload },
    effect: $$directory.updateDirectoriesFx
  });

  const domain = createDomain();

  const page = createPage();

  const addDirectoryClicked = domain.createEvent();
  const deleteDirectoryClicked =
    domain.createEvent<ManageableDirectory['id']>();
  const clearDirectoryClicked = domain.createEvent<ManageableDirectory['id']>();

  const existingDirectoryDeleteClicked =
    createEvent<ManageableDirectory['id']>();

  const changed = domain.createEvent<ManageableDirectory>();

  const validationPassed = domain.createEvent<ManageableDirectory>();

  const resetClicked = domain.createEvent();

  const submitClicked = domain.createEvent();

  const editStarted = domain.createEvent();
  const editFinished = domain.createEvent();

  const $editing = domain
    .createStore(false)
    .on(editStarted, () => true)
    .reset(editFinished);

  const $directories = domain.createStore<ManageableDirectory[]>([]);
  const $initial = domain.createStore<ManageableDirectory[]>([]);

  const $errorIds = domain.createStore<BaseDirectory['id'][]>([]);
  const $touchedIds = domain.createStore<BaseDirectory['id'][]>([]);

  const $submitting = domain.createStore(false);
  const $touched = domain.createStore(false);

  const $temporaryExist = $directories.map(directories =>
    directories.some(({ temporary }) => temporary)
  );

  const $initialMap = $initial.map(initial =>
    initial.reduce<
      Record<ManageableDirectory['id'], ManageableDirectory['name']>
    >((map, initialDirectory) => {
      map[initialDirectory.id] = initialDirectory.name;

      return map;
    }, {})
  );

  const $dirty = combine(
    $directories,
    $temporaryExist,
    $initialMap,
    (directories, temporaryExist, initialMap) =>
      temporaryExist ||
      directories.some(directory => initialMap[directory.id] !== directory.name)
  );

  const $canDeleteExisting = combine(
    $$session.$isSuperAdmin,
    $editing,
    $dirty,
    (isSuperAdmin, editing, dirty) => isSuperAdmin && !editing && !dirty
  );

  //load
  sample({
    clock: page.mounted,
    target: getDirectoriesFx
  });

  const manageableDirectoriesLoaded = getDirectoriesFx.doneData.map(
    ({ data: directories }) =>
      directories[type]
        .map(
          (directory): ManageableDirectory => ({
            ...directory,
            temporary: false
          })
        )
        .sort((a, b) => +b.id - +a.id)
  );

  $directories.on(manageableDirectoriesLoaded, (_, directories) => directories);
  $initial.on(manageableDirectoriesLoaded, (_, directories) => directories);

  //add

  const directoryCreated = sample({
    clock: addDirectoryClicked,
    source: $directories,
    fn: (directories): ManageableDirectory => {
      const ids = directories.map(({ id }) => parseInt(id));
      const maxId = Math.max(...ids);
      const newId = maxId + 1;

      return {
        id: `${newId}`,
        name: '',
        temporary: true
      };
    }
  });

  $directories.on(directoryCreated, (directories, directory) => [
    directory,
    ...directories
  ]);

  //delete
  $directories.on(deleteDirectoryClicked, (directories, directoryId) =>
    directories.filter(({ id }) => id !== directoryId)
  );
  $errorIds.on(deleteDirectoryClicked, (directories, directoryId) =>
    directories.filter(id => id !== directoryId)
  );
  $touchedIds.on(deleteDirectoryClicked, (directories, directoryId) =>
    directories.filter(id => id !== directoryId)
  );

  group('existingDirectoryDeleteClicked', () => {
    const updateExistingDirectoryFx = attach({ effect: updateDirectoryFx });

    sample({
      clock: existingDirectoryDeleteClicked,
      source: $directories,
      fn: (directories, directoryId) =>
        directories.filter(({ id }) => id !== directoryId),
      target: updateExistingDirectoryFx
    });
  });

  //clear
  sample({
    clock: clearDirectoryClicked,
    fn: id => ({ id, name: '', temporary: true }),
    target: changed
  });

  //change table value
  $directories.on(changed, (directories, changed) =>
    directories.map(directory =>
      directory.id === changed.id ? changed : directory
    )
  );

  $touchedIds.on(changed, (directories, { id }) => [...directories, id]);

  const requiredValidation = validation({
    clock: merge([changed, directoryCreated]),
    validate: payload => !!payload.name.trim().length
  });

  notification({
    clock: requiredValidation.failed.filter({
      fn: ({ temporary }) => !temporary
    }),
    message: 'Name is required',
    mode: 'error'
  });

  const maxLengthValidation = validation({
    clock: requiredValidation.passed,
    validate: payload => payload.name.length <= 255
  });

  notification({
    clock: maxLengthValidation.failed,
    message: 'Max  length 255',
    mode: 'error'
  });

  const uniqueValidation = validation({
    clock: sample({
      clock: maxLengthValidation.passed,
      source: $directories,
      fn: (directories, directory) => ({
        directory,
        names: directories.map(({ name }) => name.toUpperCase())
      })
    }),
    validate: ({ names }) => {
      const unique = [...new Set(names)];

      return unique.length === names.length;
    }
  });

  notification({
    clock: uniqueValidation.failed,
    message: 'Name is unique',
    mode: 'error'
  });

  sample({
    clock: uniqueValidation.passed,
    fn: ({ directory }) => directory,
    target: validationPassed
  });

  $errorIds
    .on(
      [requiredValidation.failed, maxLengthValidation.failed],
      (ids, { id }) => [...ids, id]
    )
    .on(uniqueValidation.failed, (ids, { directory }) => [...ids, directory.id])
    .on(validationPassed, (ids, { id }) =>
      ids.filter(errorId => errorId !== id)
    );

  //clear
  const directoriesChangesCleared = sample({
    clock: resetClicked,
    source: $initial
  });

  $directories.on(directoriesChangesCleared, (_, initial) => initial);

  reset({
    clock: directoriesChangesCleared,
    target: [$errorIds, $touched, $touchedIds]
  });

  //submit
  $submitting.on(submitClicked, () => true);
  $touched.on(submitClicked, () => true);

  $touchedIds.on(
    sample({ clock: submitClicked, source: $directories }),
    (_, directories) => directories.map(({ id }) => id)
  );

  const submitValidation = validation({
    clock: sample({
      clock: submitClicked,
      source: $directories
    }),
    validate: $errorIds.map(emptyArray)
  });

  //submit validation failed
  $submitting.reset(submitValidation.failed);

  notification({
    clock: submitValidation.failed,
    source: $errorIds,
    message: (_, errorIds) => {
      const ids = [...new Set(errorIds)].join('", "');
      const idText = errorIds.length > 1 ? 'ids' : 'id';

      return `Fulfill name for ${type}s with ${idText}: "${ids}"`;
    },
    mode: 'error'
  });

  //submit validation passed
  sample({
    clock: submitValidation.passed,
    fn: directories => directories.map(({ id, name }) => ({ id, name })),
    target: updateDirectoryFx
  });

  notification({
    clock: updateDirectoryFx.fail,
    message: 'Something goes wrong while updating',
    mode: 'error'
  });

  notification({
    clock: updateDirectoryFx.done,
    message: 'Successfully updated',
    mode: 'success'
  });

  reset({
    clock: updateDirectoryFx.done,
    target: [$touched, $errorIds]
  });

  sample({
    clock: updateDirectoryFx.done,
    target: page.mounted
  });

  reset({
    clock: updateDirectoryFx.finally,
    target: [$submitting]
  });

  domain.onCreateStore(store => store.reset(page.unmounted));

  //selectors

  const $loading = pending({
    effects: [updateDirectoryFx, getDirectoriesFx]
  });

  return {
    $dirty,
    $loading,
    $editing,
    $errorIds,
    $submitting,
    $touchedIds,
    $directories,
    $temporaryExist,
    $canDeleteExisting,
    page,
    changed: changed.prepend((d: ManageableDirectory) => ({
      ...d,
      name: d.name.trim()
    })),
    editStarted,
    editFinished,
    resetClicked,
    submitClicked,
    addDirectoryClicked,
    clearDirectoryClicked,
    deleteDirectoryClicked,
    existingDirectoryDeleteClicked
  };
};

export { createManageDirectoryModel };

export type { ManageableDirectory };
