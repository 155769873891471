import type { DataGridProps, GridValidRowModel } from '@mui/x-data-grid';

import {
  GridBody,
  GridContextProvider,
  GridErrorHandler,
  GridFooterPlaceholder,
  GridHeaderPlaceholder,
  GridRoot
} from '@mui/x-data-grid';

import { DataGridColumnHeaders } from '@mui/x-data-grid/components/DataGridColumnHeaders';

import { DataGridVirtualScroller } from '@mui/x-data-grid/components/DataGridVirtualScroller';

import { useDataGridComponent } from '@mui/x-data-grid/DataGrid/useDataGridComponent';

import { useDataGridProps } from '@mui/x-data-grid/DataGrid/useDataGridProps';

import type { DataGridProcessedProps } from '@mui/x-data-grid/models/props/DataGridProps';

import { createView } from '@shared/lib/view';

const EnhancedDataGrid = <R extends GridValidRowModel>(
  props: DataGridProps<R>
) => {
  const dgProps = useDataGridProps(props);
  const apiRef = useDataGridComponent(dgProps);

  enableMultipleFiltering(dgProps);

  return (
    <GridContextProvider apiRef={apiRef} props={dgProps}>
      <GridRoot
        className={dgProps.className}
        style={dgProps.style}
        sx={dgProps.sx}
      >
        <DataGridBody />
      </GridRoot>
    </GridContextProvider>
  );
};

const DataGridBody = createView().view(() => (
  <GridErrorHandler>
    <GridHeaderPlaceholder />

    <GridBody
      ColumnHeadersComponent={DataGridColumnHeaders}
      VirtualScrollerComponent={DataGridVirtualScroller}
    />

    <GridFooterPlaceholder />
  </GridErrorHandler>
));

function enableMultipleFiltering(props: DataGridProcessedProps) {
  props.disableMultipleColumnsFiltering = false;
}

export { EnhancedDataGrid };
