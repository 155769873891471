import { Box, Button, Grid, Step, StepLabel, Stepper } from '@mui/material';

import { createView } from '@shared/lib/view';

import { Container } from '@shared/ui';

import { AccessKeyStep } from './access-key';

import { steps } from './config';

import { ManageGridStep } from './manage-grid';

import {
  $activeStep,
  $isFirstStep,
  $isLastStep,
  $isValidStepData,
  nextClicked,
  page,
  prevClicked,
  submitClicked
} from './model';

import { UploadLinksStep } from './upload-links';

const SharepointPage = createView()
  .units({
    activeStep: $activeStep,

    valid: $isValidStepData,
    isFirstStep: $isFirstStep,
    isLastStep: $isLastStep,
    onNextClick: nextClicked,
    onPrevClick: prevClicked,
    onSubmitClick: submitClicked
  })
  .open(page.mounted)
  .close(page.unmounted)
  .view(
    ({
      activeStep,
      valid,
      isFirstStep,
      isLastStep,
      onNextClick,
      onPrevClick,
      onSubmitClick
    }) => (
      <Container p={3}>
        <Box pt={5}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Grid flex={1} container flexDirection='column'>
          {activeStep === 0 && <AccessKeyStep />}

          {activeStep === 1 && <UploadLinksStep />}

          {activeStep === 2 && <ManageGridStep />}
        </Grid>

        <Grid container alignItems='center' justifyContent='space-between'>
          <Button
            variant='contained'
            onClick={() => onPrevClick()}
            disabled={isFirstStep}
            sx={{ width: 150 }}
          >
            Prev
          </Button>

          <Button
            variant='contained'
            onClick={() => (isLastStep ? onSubmitClick() : onNextClick())}
            disabled={!valid}
            sx={{ width: 150 }}
          >
            {isLastStep ? <>Submit</> : <>Next</>}
          </Button>
        </Grid>
      </Container>
    )
  );

export { SharepointPage };
