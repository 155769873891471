import { nanoid } from 'nanoid';

import type { MetaContract } from '@shared/api';

import type {
  FileExtension,
  FileMeta,
  FulfilledFileMeta,
  LocalFile
} from './types';

class FileAdapter {
  public static fromLocal(file: File): LocalFile {
    const splattedName = file.name.split('.');
    const fileType = splattedName.slice(-1).join('') as FileExtension;
    const fileName = splattedName.slice(0, -1).join('');

    return {
      original: file,
      id: nanoid(),
      fileType,
      fileName
    };
  }
}

class MetaAdapter {
  public static fromContract(contract: MetaContract): FileMeta {
    return {
      id: nanoid(),
      from: contract.from,
      validationDate: contract.validation_date,
      validationBy: contract.validated_by,
      reviewId: nanoid(),
      project: contract.project,
      type: 'pdf',
      serviceMonth: contract.service_month,
      serviceYear: contract.service_year,
      function: contract.function,
      market: contract.market,
      name: contract.subject
    };
  }

  public static toDto(dto: FulfilledFileMeta): MetaContract {
    return {
      id: dto.id,
      from: dto.from,
      validation_date: dto.validationDate,
      validated_by: dto.validationBy,
      reviewId: dto.reviewId,
      project: dto.project,
      type: dto.type,
      service_month: dto.serviceMonth,
      service_year: dto.serviceYear,
      function: dto.function,
      market: dto.market,
      subject: dto.name
    };
  }
}

export { FileAdapter, MetaAdapter };
