import type { GridProps, PaperProps } from '@mui/material';

import { Grid, Paper } from '@mui/material';

import type { PropsWithChildren, ReactNode } from 'react';

import { createView } from '@shared/lib/view';

type PageCenterLayoutProps = PropsWithChildren<{
  sameAspectRatio?: boolean;
  center?: boolean;
  size?: number;

  disablePadding?: boolean;

  before?: ReactNode;

  props?: {
    root?: GridProps;
    paper?: PaperProps & GridProps;
    before?: GridProps;
    inner?: GridProps;
  };
}>;

const PageCenterLayout = createView<PageCenterLayoutProps>()
  .defaultProps({ size: 500 })
  .view(
    ({
      children,
      sameAspectRatio,
      center,
      size,
      before,
      disablePadding,
      props
    }) => (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        flex={1}
        {...(props?.root ?? {})}
      >
        <Grid
          container
          item
          component={Paper}
          elevation={3}
          height={sameAspectRatio ? size : undefined}
          width={size}
          p={disablePadding ? 0 : 1}
          {...(props?.paper ?? {})}
        >
          {before && (
            <Grid item container width={size} {...(props?.before ?? {})}>
              {before}
            </Grid>
          )}

          <Grid
            item
            container
            p={disablePadding ? 0 : 4}
            {...(center
              ? { alignItems: 'center', justifyContent: 'center' }
              : {})}
            {...(props?.inner ?? {})}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    )
  );

export { PageCenterLayout };
