import { attach } from 'effector';

import { api } from '@shared/api';

import { trimPayload } from '@shared/lib/prototype';

const getDirectoriesFx = attach({
  mapParams: trimPayload,
  effect: api.directories.getDirectories
});

const updateDirectoriesFx = attach({
  effect: api.directories.updateDirectories
});

const getAllowedFunctionsFx = attach({
  mapParams: trimPayload,
  effect: api.directories.getAllowedWriteFunctions
});
const getAllowedMarketsFx = attach({
  mapParams: trimPayload,
  effect: api.directories.getAllowedWriteMarkets
});

export {
  getDirectoriesFx,
  updateDirectoriesFx,
  getAllowedFunctionsFx,
  getAllowedMarketsFx
};
