import type { GridApi } from '@mui/x-data-grid';

import copyToClipboard from 'copy-to-clipboard';

import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
  restore,
  sample,
  split
} from 'effector';

import { createGate } from 'effector-react';

import { combineEvents, some } from 'patronum';

import type { MutableRefObject } from 'react';

import { DocAdapter } from '@pages/bucket/lib';

import { $$bucket } from '@entities/bucket';

import { $$directory } from '@entities/directories';

import { $$session } from '@entities/session';

import { group } from '@shared/lib/effector-group';

import { trimPayload } from '@shared/lib/prototype';

import { notification } from '@shared/notification';

import { deleteFileFx } from './api';

import {
  breadcrumbsLevels,
  resetMarketLevels,
  resetMonthLevels,
  resetProjectLevels,
  resetYearLevels
} from './config';

import type {
  BreadcrumbsLevel,
  DocumentInfo,
  FileMetaExplorerMap,
  RowDir,
  TableMode
} from './types';

const getDirectoriesFx = attach({ effect: $$directory.getDirectoriesFx });
const getDocsFx = attach({ effect: $$bucket.getDocsFx });
const refreshDocsFx = attach({ effect: getDocsFx });
const downloadFileFx = attach({ effect: $$bucket.downloadFileFx });

const saveDownloadedFileToSystemFx = createEffect<
  { filename: string; data: Blob },
  void
>();

const enter = createEvent();
const exit = createEvent();

const refreshClicked = createEvent();

const rowClicked = createEvent<RowDir>();
const breadcrumbsClicked = createEvent<BreadcrumbsLevel>();

const downloadFileClicked = createEvent<DocumentInfo>();

const modeChanged = createEvent();

const selectionChanged = createEvent<DocumentInfo['id'][]>();

const downloadSelectedFiles = createEvent();

const cellDoubleClicked = createEvent<string>();
const copyToClipboardFx = createEffect((text: string) => {
  copyToClipboard(text);
});

const ApiRefGate = createGate<MutableRefObject<GridApi>>();
const $tableApiRef = restore(ApiRefGate.open, null);

const resetTableStateFx = attach({
  source: $tableApiRef,
  effect: api => {
    if (!api) return null;

    api.current.restoreState({
      filter: {},
      pagination: {},
      columns: {},
      sorting: {}
    });
  }
});

const $listDocs = createStore<DocumentInfo[]>([]);

const $mode = createStore<TableMode>('list');
const $selectedFileIds = restore(selectionChanged, []);

const $selectedFiles = combine($selectedFileIds, $listDocs, (ids, list) =>
  list.filter(({ id }) => ids.includes(id))
);

const $market = createStore<string | null>(null);
const $project = createStore<string | null>(null);
const $year = createStore<string | null>(null);
const $month = createStore<string | null>(null);

const $$deleteFile = group('delete file', () => {
  const deleteClicked = createEvent<DocumentInfo>();

  const dialogClosed = createEvent();
  const dialogConfirmed = createEvent();

  const $dialogOpened = createStore(false);

  const $isSomeDocsCanBeDeleted = $listDocs.map(list =>
    list.some(({ canDelete }) => canDelete)
  );

  group('single delete', () => {
    $dialogOpened.on(deleteClicked, () => true);

    sample({
      clock: dialogConfirmed,
      source: deleteClicked,
      fn: ({ storageKey }) => ({ key: storageKey }),
      target: deleteFileFx
    });

    $dialogOpened.reset(dialogClosed, deleteFileFx.done);

    $listDocs.on(deleteFileFx.done, (list, { params }) =>
      list.filter(({ storageKey }) => storageKey !== params.key)
    );

    notification({
      clock: deleteFileFx.done,

      message: ({ params }) =>
        `File ${params.key.split('/').reverse()[0]} deleted`,
      mode: 'success'
    });

    notification({
      clock: deleteFileFx.fail,

      message: ({ params }) =>
        `File ${params.key.split('/').reverse()[0]} not deleted`,
      mode: 'error',
      http: true
    });
  });

  return {
    $isSomeDocsCanBeDeleted,
    $dialogOpened,
    $pending: deleteFileFx.pending,
    deleteClicked,
    dialogClosed,
    confirmed: dialogConfirmed
  };
});

const $levels = combine(
  $market,
  $project,
  $year,
  $month,
  (...levels) => levels
);
const $filledLevels = combine(
  $levels,
  levels => levels.filter(Boolean) as string[]
);

const $currentLevel = $filledLevels.map(levels => levels.length);

//load
sample({
  clock: enter,
  target: [getDocsFx.prepend(trimPayload), getDirectoriesFx]
});

const dataLoaded = combineEvents({
  events: {
    docs: getDocsFx.doneData,
    directories: getDirectoriesFx.doneData.map(({ data }) => data)
  }
});

$listDocs.on(
  sample({
    clock: dataLoaded,
    // eslint-disable-next-line effector/no-unnecessary-combination
    source: combine($$session.$email, $$session.$isAdmin, (email, isAdmin) =>
      !email ? null : { email, isAdmin }
    ),
    filter: Boolean,
    fn: ({ email, isAdmin }, { docs: { data }, directories }) =>
      data
        .slice()
        .sort(
          (a, b) =>
            new Date(b.Metadata.validation_date).getTime() -
            new Date(a.Metadata.validation_date).getTime()
        )
        .map(doc => DocAdapter.from(doc, directories, email, isAdmin))
  }),
  (_, docs) => docs
);

//refresh data
sample({
  clock: refreshClicked,
  target: refreshDocsFx
});

const $isExplorerMode = $mode.map(mode => mode === 'explorer');

const levelsReseed = sample({
  clock: refreshDocsFx.doneData,
  source: { market: $market, project: $project, year: $year, month: $month },
  filter: $isExplorerMode,
  fn: ({ market, month, year, project }, { relativePathsObj }) => {
    const exist = { market: false, month: false, year: false, project: false };

    if (!market) return exist;

    const formattedMarket = market === 'Common' ? 'unknown' : market;

    exist.market = !!relativePathsObj[formattedMarket];

    if (!project) return exist;

    exist.project = !!relativePathsObj[formattedMarket][project];

    if (!year) return exist;

    exist.year = !!relativePathsObj[formattedMarket][project][year];

    if (!month) return exist;

    exist.month = !!relativePathsObj[formattedMarket][project][year][month];

    return exist;
  }
});

$market.reset(levelsReseed.filter({ fn: ({ market }) => !market }));
$project.reset(levelsReseed.filter({ fn: ({ project }) => !project }));
$year.reset(levelsReseed.filter({ fn: ({ year }) => !year }));
$month.reset(levelsReseed.filter({ fn: ({ month }) => !month }));

//mod changed
$mode.on(modeChanged, mode => (mode === 'list' ? 'explorer' : 'list'));

$market.reset(modeChanged);
$project.reset(modeChanged);
$year.reset(modeChanged);
$month.reset(modeChanged);

//row clicked

const exploreTableRowClicked = sample({
  clock: rowClicked,
  filter: $isExplorerMode,
  fn: ({ name }) => name
});

split({
  source: exploreTableRowClicked,

  match: {
    marketChanged: $currentLevel.map(level => level === 0),
    projectChanged: $currentLevel.map(level => level === 1),
    yearChanged: $currentLevel.map(level => level === 2),
    monthChanged: $currentLevel.map(level => level === 3)
  },

  cases: {
    marketChanged: $market,
    projectChanged: $project,
    yearChanged: $year,
    monthChanged: $month
  }
});

const levelChanged = merge([$market, $project, $year, $month]);

sample({
  clock: levelChanged,
  target: resetTableStateFx
});

//cell double-clicked
const $allLevelsFilled = combine(
  $levels,
  $filledLevels,
  (levels, filled) => levels.length === filled.length
);

const $isListMode = $mode.map(mode => mode === 'list');

const $isFilesColumnsShow = some({
  stores: [$allLevelsFilled, $isListMode],
  predicate: true
});

sample({
  clock: cellDoubleClicked,
  source: $isFilesColumnsShow,
  filter: (isFileShow, text) => !!text && isFileShow,
  fn: (_, text) => text,
  target: copyToClipboardFx
});

notification({
  clock: copyToClipboardFx.done,
  message: ({ params }) => `Copied to clipboard: ${params}`,
  mode: 'success'
});

//breadcrumb clicked
const levelChangedFor = (levels: BreadcrumbsLevel[]) =>
  breadcrumbsClicked.filter({ fn: level => levels.includes(level) });

$market.on(levelChangedFor(resetMarketLevels), () => null);
$project.on(levelChangedFor(resetProjectLevels), () => null);
$year.on(levelChangedFor(resetYearLevels), () => null);
$month.on(levelChangedFor(resetMonthLevels), () => null);

//download file clicked

sample({
  clock: downloadFileClicked,
  fn: ({ storageKey }) => ({ key: storageKey }),
  target: downloadFileFx
});

const fileDownloadToMemory = combineEvents({
  events: { clicked: downloadFileClicked, downloaded: downloadFileFx.done }
});

sample({
  clock: fileDownloadToMemory,
  fn: ({ clicked: { name }, downloaded: { result: data } }) => ({
    filename: name,
    data: data
  }),
  target: saveDownloadedFileToSystemFx
});

saveDownloadedFileToSystemFx.use(({ filename, data }) => {
  const url = window.URL.createObjectURL(data);

  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.download = filename;
  anchor.click();

  window.URL.revokeObjectURL(url);
});

notification({
  clock: saveDownloadedFileToSystemFx.fail,
  message: 'Something went wrong while download',
  mode: 'error'
});

//download selected clicked

const batchDownloadFx = attach({
  source: $selectedFiles,
  effect: async files => {
    for (const file of files) {
      const data = await downloadFileFx({ key: file.storageKey });

      await saveDownloadedFileToSystemFx({ filename: file.name, data });
    }
  }
});

sample({
  clock: downloadSelectedFiles,
  target: batchDownloadFx
});

//leave
$listDocs.reset(exit);

$market.reset(exit);
$project.reset(exit);
$year.reset(exit);
$month.reset(exit);

$mode.reset(exit);

//selectors
const $loading = getDocsFx.pending;

const $explorerDocs = $listDocs.map<FileMetaExplorerMap | null>(list => {
  if (!list.length) return null;

  return DocAdapter.toExplorerMap(list);
});

const $explorerItems = combine(
  $explorerDocs,
  $filledLevels,
  (docs, filledLevels) => {
    if (!docs) return [];

    const sourceLevel = filledLevels.reduce<
      Record<string, unknown> | DocumentInfo[]
    >((obj, level) => {
      if (Array.isArray(obj)) {
        return obj;
      }

      return obj?.[level] as Record<string, unknown>;
    }, docs);

    return [
      ...(Array.isArray(sourceLevel)
        ? sourceLevel
        : Object.keys(sourceLevel ?? {}).map((name): RowDir => ({ name })))
    ];
  }
);

const $items = combine(
  $mode,
  $explorerItems,
  $listDocs,
  (mode, explorer, list) =>
    (mode === 'explorer' ? [...explorer] : [...list]) as DocumentInfo[]
);

const $breadcrumbLevels = $filledLevels.map(levels =>
  ['Explorer', ...levels].map((name, index) => ({
    name,
    level: breadcrumbsLevels[index]
  }))
);

const $level = $filledLevels.map(levels => levels.length);

const $isSomeFileSelected = $selectedFileIds.map(
  selection => selection.length > 0
);

export {
  $mode,
  $level,
  $items,
  $loading,
  $filledLevels,
  $isListMode,
  $isExplorerMode,
  $isFilesColumnsShow,
  $isSomeFileSelected,
  $breadcrumbLevels,
  $$deleteFile,
  enter,
  exit,
  rowClicked,
  breadcrumbsClicked,
  downloadFileClicked,
  modeChanged,
  refreshClicked,
  ApiRefGate,
  selectionChanged,
  downloadSelectedFiles,
  cellDoubleClicked
};
