import { Grid, Link } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import { RegisterForm } from '@features/auth/register';

import { routes } from '@shared/config';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { confirmEmailAfterRefreshedClicked } from '../model';

const RegisterPage = createView()
  .units({
    onLinkClick: confirmEmailAfterRefreshedClicked.prepend(trimPayload)
  })
  .view(({ onLinkClick }) => (
    <RegisterForm>
      <Grid container alignItems='center' justifyContent='center' mt={-1.5}>
        <Link
          component={RouterLink}
          to={routes.auth.signUp.confirmEmailAfterTimeout()}
          onClick={onLinkClick}
        >
          Confirm email
        </Link>
      </Grid>
    </RegisterForm>
  ));

export { RegisterPage };
