import type { Field } from 'effector-forms';

import { useField } from 'effector-forms';

const useFieldError = <T>(field: Field<T>) => {
  const { errorText, isTouched, isValid, hasError } = useField(field);

  return {
    isError: isTouched && !isValid,
    errorText: isTouched && hasError() && errorText()
  };
};

export { useFieldError };
