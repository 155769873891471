import { attach } from 'effector';

import { api } from '@shared/api';

import { trimPayload } from '@shared/lib/prototype';

const loginFx = attach({ effect: api.auth.loginInit });
const refreshFx = attach({ effect: api.auth.refreshAccessTokens });
const refreshAfter401Fx = attach({ effect: refreshFx });
const meFx = attach({ mapParams: trimPayload, effect: api.auth.getUserInfo });

export { loginFx, refreshFx, refreshAfter401Fx, meFx };
