import type { DirectoryDto } from '@shared/api';

import type { UploadLocalFileToBucketDto } from '../types';

class MetaAdapter {
  public static toUploadDto(
    { file, meta }: UploadLocalFileToBucketDto,
    directories: DirectoryDto
  ): Required<{
    market?: string;
    function?: string;
    service_year?: string;
    service_month?: string;
    subject?: string;
    from?: string;
    validation_date?: string;
    validated_by?: string;
    file?: File;
    description?: string;
    id: string;
  }> {
    return {
      file: file.original,
      id: meta.id,
      from: meta.from,
      validated_by: meta.validationBy,
      market: Array.isArray(meta.market)
        ? meta.market
            .map(
              name =>
                directories.market.find(market => market.name === name)?.id
            )
            .filter(Boolean)
            .join(',')
        : meta.market,
      description: meta.project,
      function:
        directories.function.find(
          dirFunction => dirFunction.name === meta.function
        )?.id ??
        meta.function ??
        '',
      service_month: meta.serviceMonth.toString(),
      service_year: meta.serviceYear.toString(),
      subject: meta.name,
      validation_date: new Date().toUTCString()
    };
  }
}

export { MetaAdapter };
