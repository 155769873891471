import { Grid, Paper, Skeleton } from '@mui/material';

import { createView } from '@shared/lib/view';

import { QR_SIZE } from './config';

type QRProps = {
  qr: string;

  loading?: boolean;
};

const QR = createView<QRProps>()
  .defaultProps({ loading: false })
  .view(({ loading, qr }) => (
    <Grid
      item
      container
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      component={Paper}
      width={QR_SIZE}
      height={QR_SIZE}
    >
      {loading || !qr ? (
        <Skeleton variant='rectangular' width={QR_SIZE} height={QR_SIZE} />
      ) : (
        <img src={qr} alt='qr' width={QR_SIZE} height={QR_SIZE} />
      )}
    </Grid>
  ));

export { QR };

export type { QRProps };
