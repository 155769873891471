import { attach, createEffect, sample } from 'effector';

import { createManageFileMeta } from '@features/manage-file-meta';

import type { FulfilledFileMeta, LocalFileId } from '@entities/file';

import { fileModel, MetaAdapter } from '@entities/file';

import { routes } from '@shared/config';

import { pushFx } from '@shared/history';

import { $links, getUrl } from '../upload-links';

const uploadSPFilesMetaFx = createEffect<
  FulfilledFileMeta[],
  PromiseSettledResult<LocalFileId>[]
>();
const uploadSingleSPFileMetaFx = attach({
  effect: fileModel.uploadSPFileMetaFx
});

const getFilesMetaFx = attach({
  source: $links.map(links => links.map(getUrl)),
  effect: fileModel.getSPFilesMetaByUrlsFx
});

const manageFileMetaModel = createManageFileMeta();

sample({
  clock: manageFileMetaModel.page.mounted,
  target: getFilesMetaFx
});

sample({
  clock: getFilesMetaFx.doneData,
  fn: metas => metas.map(MetaAdapter.fromContract),
  target: manageFileMetaModel.metaLoaded
});

sample({
  clock: manageFileMetaModel.submitted,
  target: uploadSPFilesMetaFx
});

uploadSPFilesMetaFx.use(async files =>
  Promise.allSettled(
    files.map(async file => {
      await uploadSingleSPFileMetaFx(MetaAdapter.toDto(file));

      return file.id;
    })
  )
);

sample({
  clock: uploadSingleSPFileMetaFx.done,
  fn: ({ params }) => ({ id: params.id, fileName: params.subject }),
  target: manageFileMetaModel.singleMetaUpload.done
});

sample({
  clock: uploadSingleSPFileMetaFx.fail,
  fn: ({ params }) => ({ id: params.id, fileName: params.subject }),
  target: manageFileMetaModel.singleMetaUpload.fail
});

sample({
  clock: uploadSPFilesMetaFx.finally,
  target: manageFileMetaModel.filesMetaUploaded
});

sample({
  clock: uploadSPFilesMetaFx.finally,
  source: manageFileMetaModel.$metas,
  filter: metas => metas.length === 0,
  target: pushFx.prepend(() => routes.bucket())
});

export { manageFileMetaModel };
