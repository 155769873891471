import { createEvent, sample } from 'effector';

import { $$setupMFA } from '@features/auth/setup-mfa';

import { routes } from '@shared/config';

import { $pathname, pushFx } from '@shared/history';

import { group } from '@shared/lib/effector-group';

import { createPage, createRouteStepper } from '@shared/lib/units';

import { steps } from './config';

const page = createPage();

const setupBackClicked = createEvent();
const confirmBackClicked = createEvent();

const confirmClicked = createEvent();

const $activeStep = createRouteStepper(steps, $pathname);

group('go back', () => {
  sample({
    clock: setupBackClicked,
    target: pushFx.prepend(() => routes.auth.login())
  });

  sample({
    clock: confirmBackClicked,
    target: pushFx.prepend(() => routes.auth.mfa.setup())
  });
});

group('load', () => {
  sample({
    clock: page.mounted,
    target: $$setupMFA.getQRFx
  });
});

group('setup confirmed', () => {
  sample({
    clock: confirmClicked,
    target: pushFx.prepend(() => routes.auth.mfa.confirm())
  });
});

export {
  $activeStep,
  page,
  confirmClicked,
  setupBackClicked,
  confirmBackClicked
};
