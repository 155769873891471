import { Typography } from '@mui/material';

import { Navigate, Route, Routes } from 'react-router-dom';

import {
  ChangePasswordPage,
  ConfirmLoginMfaPage,
  LoginPage
} from '@pages/auth/login';

import { ConfirmMFAPage, SetupMFALayout, SetupMFAPage } from '@pages/auth/mfa';

import {
  ConfirmEmailPage,
  ConfirmEmailTimeoutPage,
  RegisterPage,
  SignUpLayout
} from '@pages/auth/sign-up';

import { MainLayout } from '@widgets/layouts/main-layout';

import { $$session } from '@entities/session';

import { routes } from '@shared/config';

import { createView } from '@shared/lib/view';

import { PageCenterLayout } from '@shared/ui';

import { BucketPage } from './bucket';

import {
  DirectoryFunctionPage,
  DirectoryMarketPage,
  DirectoryPage
} from './directory';

import { SharepointPage } from './sharepoint';

import { UploadPage } from './upload';

const Pages = createView()
  .units({
    isAuthenticated: $$session.$isAuthenticated,
    isNotAllowedToUseApp: $$session.$isNotAllowedToUseApp
  })
  .view(({ isAuthenticated, isNotAllowedToUseApp }) => {
    if (!isAuthenticated) {
      return (
        <MainLayout hideTabs>
          <Routes>
            <Route path={routes.auth.login()} element={<LoginPage />} />

            <Route
              path={routes.auth.login.mfa()}
              element={<ConfirmLoginMfaPage />}
            />

            <Route
              path={routes.auth.login.changePassword()}
              element={<ChangePasswordPage />}
            />

            <Route path={routes.auth.signUp()} element={<SignUpLayout />}>
              <Route
                path={routes.auth.signUp.register()}
                element={<RegisterPage />}
              />

              <Route
                path={routes.auth.signUp.confirmEmail()}
                element={<ConfirmEmailPage />}
              />

              <Route
                path={routes.auth.signUp.confirmEmailAfterTimeout()}
                element={<ConfirmEmailTimeoutPage />}
              />
            </Route>

            <Route path={routes.auth.mfa()} element={<SetupMFALayout />}>
              <Route
                path={routes.auth.mfa.setup()}
                element={<SetupMFAPage />}
              />

              <Route
                path={routes.auth.mfa.confirm()}
                element={<ConfirmMFAPage />}
              />
            </Route>

            <Route path='*' element={<Navigate to={routes.auth.login()} />} />
          </Routes>
        </MainLayout>
      );
    }

    if (isNotAllowedToUseApp) {
      return (
        <MainLayout hideTabs>
          <PageCenterLayout>
            <Typography display='inline-block' align='center' width='100%'>
              You don`t have access to content
            </Typography>
          </PageCenterLayout>
        </MainLayout>
      );
    }

    return (
      <MainLayout>
        <Routes>
          <Route path={routes.bucket()} element={<BucketPage />} />

          <Route path={routes.upload()} element={<UploadPage />} />

          <Route path={routes.sharepoint()} element={<SharepointPage />} />

          <Route path={routes.directories()} element={<DirectoryPage />}>
            <Route
              path={routes.directories.market()}
              element={<DirectoryMarketPage />}
            />

            <Route
              path={routes.directories.function()}
              element={<DirectoryFunctionPage />}
            />

            <Route
              path={routes.directories()}
              element={<Navigate to={routes.directories.market()} />}
            />
          </Route>

          <Route path='*' element={<Navigate to={routes.bucket()} />} />
        </Routes>
      </MainLayout>
    );
  });

export { Pages };
