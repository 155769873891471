import axios from 'axios';

import {
  createEffect,
  createEvent,
  createStore,
  merge,
  sample
} from 'effector';

import { combineEvents, interval } from 'patronum';

import { addTokenToHeader } from '@entities/session/lib';

import { $user } from '@entities/session/model/sesion';

import type { Tokens } from '@shared/api';

import { api, http } from '@shared/api';

import { group } from '@shared/lib/effector-group';

import { loginFx, meFx, refreshAfter401Fx, refreshFx } from '../api';

import { ACCESS_TOKEN_LIFESPAN } from '../config';

const $refreshToken = createStore<Tokens['refreshToken'] | null>(null);

//login
$refreshToken.on(
  [api.auth.loginComplete.doneData, api.auth.verifyMfaToken.doneData],
  (_, { data: { refreshToken } }) => refreshToken
);

sample({
  clock: loginFx.done,
  target: meFx
});

// window focus
const { windowFocused } = group('window focus', () => {
  const subscribeDocumentFocusFx = createEffect();
  const unsubscribeDocumentFocusFx = createEffect();

  const windowFocused = createEvent<unknown>();

  sample({
    clock: [api.auth.loginComplete.doneData, api.auth.verifyMfaToken.doneData],
    target: subscribeDocumentFocusFx
  });

  subscribeDocumentFocusFx.use(() => {
    window.addEventListener('focus', windowFocused);
  });

  sample({
    clock: refreshFx.fail,
    target: unsubscribeDocumentFocusFx
  });

  unsubscribeDocumentFocusFx.use(() => {
    window.removeEventListener('focus', windowFocused);
  });

  return { windowFocused };
});

group('auto-refresh', () => {
  const refreshInterval = interval({
    timeout: ACCESS_TOKEN_LIFESPAN / 2,
    start: merge([loginFx.done, windowFocused]),
    stop: refreshFx.fail
  });

  const refreshTimeoutTicked = sample({
    clock: refreshInterval.tick,
    filter: refreshInterval.isRunning
  });

  sample({
    clock: [refreshTimeoutTicked, windowFocused],
    source: $refreshToken,
    filter: Boolean,
    fn: refreshToken => ({ refreshToken }),
    target: refreshFx
  });

  $refreshToken.on(
    refreshFx.doneData,
    (_, { data: { refreshToken } }) => refreshToken
  );
});

group('refresh', () => {
  const accessTokenExpired = sample({
    clock: http.baseRequestFx.fail,
    filter: ({ error }) =>
      axios.isAxiosError(error) && error.response?.status === 401
  });

  sample({
    clock: accessTokenExpired,
    source: $refreshToken,
    filter: Boolean,
    fn: refreshToken => ({ refreshToken }),
    target: refreshAfter401Fx
  });

  $refreshToken.on(
    refreshAfter401Fx.doneData,
    (_, { data: { refreshToken } }) => refreshToken
  );

  const tokenRefreshed = combineEvents({
    events: { refreshDone: refreshAfter401Fx.doneData, accessTokenExpired }
  });

  sample({
    clock: tokenRefreshed,
    filter: ({ refreshDone }) => !!refreshDone.data?.accessToken,
    fn: ({ accessTokenExpired, refreshDone }) =>
      addTokenToHeader(
        accessTokenExpired.params,
        refreshDone.data.accessToken!
      ),
    target: http.baseRequestFx
  });

  $refreshToken.reset(refreshFx.fail);

  $user.on($refreshToken, (user, token) => (!token ? null : user));
});

export { loginFx, meFx };
