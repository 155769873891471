import type { ErrorType } from './types';

const errorMap: Record<ErrorType, ErrorType> = {
  DUPLICATE: 'DUPLICATE',
  INVALID_URL: 'INVALID_URL',
  NOT_FOUND: 'NOT_FOUND'
};

const errorTypeMessageMap: Record<ErrorType, string> = {
  DUPLICATE: 'Duplicated url',
  INVALID_URL: 'Invalid url',
  NOT_FOUND: 'File not found'
};

export { errorTypeMessageMap, errorMap };
