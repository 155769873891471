import { restore } from 'effector';

import { meFx } from '../api';

const $user = restore(
  meFx.doneData.map(({ data }) => data),
  null
);
const $email = $user.map(user => (user ? user.email : null));

const $isAuthenticated = $user.map(Boolean);

const $attributes = $user.map(user => {
  if (!user)
    return {
      function: { read: [], write: [] },
      market: { read: [], write: [] }
    };

  return {
    function: {
      read: user.customAttributes.functionsRead ?? [],
      write: user.customAttributes.functionsWrite ?? []
    },
    market: {
      read: user.customAttributes.marketsRead ?? [],
      write: user.customAttributes.marketsWrite ?? []
    }
  };
});

const $groups = $user.map(user => (!user ? [] : user.groups));

const $viewer = $user.map(user => {
  if (!user) return '';

  return `${user.firstName} ${user.lastName}`;
});

export { $viewer, $isAuthenticated, $user, $email, $attributes, $groups };
