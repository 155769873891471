import { directoryRoutes } from './directory.routes';

import { loginRoutes } from './login.routes';

import { mfaRoutes } from './mfa.routes';

import { signUpRoutes } from './sign-up.routes';

const routes = {
  bucket: () => '/bucket',
  upload: () => '/upload',
  sharepoint: () => '/sharepoint',

  auth: {
    login: loginRoutes,
    signUp: signUpRoutes,
    mfa: mfaRoutes
  },

  directories: directoryRoutes
};

export { routes };
