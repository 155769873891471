import { Grid } from '@mui/material';

import type { FormEvent } from 'react';

import { $$setupMFA } from '@features/auth/setup-mfa';

import { QR } from '@entities/session';

import { createView } from '@shared/lib/view';

import { StepperFooterFormControls } from '@shared/ui';

import { confirmClicked, setupBackClicked } from '../model';

const SetupMFAPage = createView()
  .units({
    qr: $$setupMFA.$qr,
    loading: $$setupMFA.getQRFx.pending,
    onBackClick: setupBackClicked,
    onSubmit: confirmClicked.prepend((e: FormEvent) => e.preventDefault())
  })
  .view(({ loading, qr, onBackClick, onSubmit }) => (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      gap={3}
      component='form'
      onSubmit={onSubmit}
    >
      <QR loading={loading} qr={qr} />

      <StepperFooterFormControls
        submitText='Confirm setup'
        onGoBackClick={onBackClick}
        disableHorizontalPaddings
        disabledBottomPaddings
      />
    </Grid>
  ));

export { SetupMFAPage };
