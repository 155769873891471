import type { AxiosError } from 'axios';

import axios from 'axios';

const createErrorMatcher =
  (name: string) =>
  (
    error: unknown
  ): error is AxiosError & {
    response: { data: { name: string; message: string } };
  } =>
    axios.isAxiosError(error) &&
    hasBody(error) &&
    hasName(error.response.data) &&
    hasMessage(error.response.data) &&
    error.response?.data.name === name;

function hasBody(
  source: AxiosError
): source is AxiosError & { response: { data: object } } {
  return !!source.response?.data && typeof source.response.data === 'object';
}

function hasName<T extends object>(source: T): source is T & { name: string } {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.hasOwn(source, 'name') && typeof source.name === 'string';
}

function hasMessage<T extends object>(
  source: T
): source is T & { message: string } {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return Object.hasOwn(source, 'message') && typeof source.message === 'string';
}

export { createErrorMatcher };
