import { createEvent, restore } from 'effector';

const changed = createEvent<string>();
const $accessKey = restore(changed, '');

const $isValid = $accessKey.map(isValid);

function isValid(str: string) {
  return !!str.trim();
}

export { $accessKey, $isValid, changed };
