import { environment } from '@shared/config';

const isEmailDomainValid = (value = '') =>
  environment.strictEmail
    ? value.endsWith('@pmi.com') ||
      value.endsWith('@contracted.pmi.com') ||
      value.endsWith('@rbhinc.ca') ||
      value.endsWith('@sampoerna.com')
    : !!value;

const canEnterEmailCode = (value = '') => value.length < 7;
const canEnterTOTP = (value = '') => value.length < 7;

export { isEmailDomainValid, canEnterEmailCode, canEnterTOTP };
