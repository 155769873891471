import type { FileMeta } from '@entities/file';

const fieldHeaderNameMap: Record<keyof FileMeta, string> = {
  id: 'ID',
  name: 'File Name',
  market: 'Market',
  function: 'Function',
  type: 'Type',
  from: 'From',
  serviceMonth: 'Service Month',
  serviceYear: 'Service Year',
  project: 'Project/Description',
  reviewId: 'REVIEWID',
  validationBy: 'Validated By',
  validationDate: 'Validation Date'
};

const serviceMonths = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' }
] as const;

const serviceMonthsMap = serviceMonths.reduce(
  (map, month) => ({ ...map, [month.value]: month }),
  {} as Record<string, { label: string; value: string }>
);

const lastValidYearServiceMonths = serviceMonths.filter(
  month => new Date().getMonth() + 1 >= +month.value
);

const serviceYears = Array(new Date().getFullYear() - 2000 + 1)
  .fill(2000)
  .map((year: number, index) => year + index)
  .map(year => String(year));

export {
  serviceMonthsMap,
  fieldHeaderNameMap,
  serviceMonths,
  serviceYears,
  lastValidYearServiceMonths
};
