import axios from 'axios';

import { attach, createEffect, sample } from 'effector';

import { createManageFileMeta } from '@features/manage-file-meta';

import { UploadFilesLocalAdapter } from '@features/upload-local-file';

import { $$directory } from '@entities/directories';

import type { LocalFileId } from '@entities/file';

import { fileModel } from '@entities/file';

import { $$session } from '@entities/session';

import type { DirectoryDto } from '@shared/api';

import { getSuccessSettledResults } from '@shared/lib/prototype';

import { createPage } from '@shared/lib/units';

import { MetaAdapter } from './lib';

import type { UploadLocalFileToBucketDto } from './types';

const manageMetaModel = createManageFileMeta({
  skipValidationFieldChecks: true
});

const uploadFilesToBucketFx = createEffect<
  { directories: DirectoryDto; files: UploadLocalFileToBucketDto[] },
  PromiseSettledResult<LocalFileId>[]
>();
const uploadSingleFileToBucketFx = attach({
  effect: fileModel.uploadLocalFileFx
});

const page = createPage();

sample({
  clock: page.mounted,
  target: manageMetaModel.page.mounted
});

const metaUploaded = sample({
  clock: fileModel.localFilesUploaded,
  source: $$session.$viewer,
  fn: (viewer, files) =>
    files.map(file => UploadFilesLocalAdapter.fromFile({ file, viewer }))
});

sample({
  clock: metaUploaded,
  target: manageMetaModel.metaLoaded
});

sample({
  clock: manageMetaModel.deleteClicked,
  target: fileModel.localFileDeleted
});

sample({
  clock: manageMetaModel.resetClicked,
  target: fileModel.localFilesCleared
});

sample({
  clock: manageMetaModel.submitted,
  source: {
    localFiles: fileModel.$localFiles,
    directories: $$directory.$allowedDirectories
  },
  fn: ({ localFiles, directories }, metas) => ({
    files: localFiles
      .map(file => {
        const meta = metas.find(meta => meta.id === file.id);

        return meta ? { file, meta } : null;
      })
      .filter((dto): dto is UploadLocalFileToBucketDto => !!dto),
    directories
  }),

  target: uploadFilesToBucketFx
});

uploadFilesToBucketFx.use(async ({ directories, files }) =>
  Promise.allSettled(
    files.map(async file => {
      await uploadSingleFileToBucketFx(
        MetaAdapter.toUploadDto(file, directories)
      );

      return file.meta.id;
    })
  )
);

sample({
  clock: uploadSingleFileToBucketFx.done,
  fn: ({ params }) => ({ id: params.id, fileName: params.subject }),
  target: manageMetaModel.singleMetaUpload.done
});

sample({
  clock: uploadSingleFileToBucketFx.fail,
  fn: ({ params, error }) => ({
    id: params.id,
    fileName: params.subject,
    isDuplicationError:
      // @ts-expect-error Because skip typeguard
      axios.isAxiosError(error) && error.response?.data?.code === '0301'
  }),
  target: manageMetaModel.singleMetaUpload.fail
});

sample({
  clock: uploadFilesToBucketFx.finally,
  target: manageMetaModel.filesMetaUploaded
});

sample({
  clock: uploadFilesToBucketFx.doneData,
  fn: results => getSuccessSettledResults(results),
  target: fileModel.localFilesCleared
});

sample({
  clock: page.mounted,
  target: manageMetaModel.page.unmounted
});

export { page, manageMetaModel };
