import { combine } from 'effector';

import { Role } from '../types';

import { $attributes, $groups } from './sesion';

const $isSuperAdmin = $groups.map(groups => groups.includes(Role.SuperAdmin));

const $isAdmin = $groups.map(
  groups => groups.includes(Role.Admin) || groups.includes(Role.SuperAdmin)
);

const $isNotAllowedToUseApp = combine(
  $attributes,
  $groups,
  (attributes, groups) => {
    const isEmptyGroups = groups.length === 0;
    const isEmptyAttributes = [attributes.market, attributes.function].every(
      ({ read, write }) => read.length === 0 && write.length === 0
    );

    return isEmptyAttributes && isEmptyGroups;
  }
);

const $isCustomRole = $groups.map(groups => groups.length === 0);

const $isWriterCustom = $attributes.map(
  attributes =>
    attributes.market.write.length > 0 && attributes.function.write.length > 0
);

const $isUploadAllowed = combine(
  $groups,
  $isWriterCustom,
  (groups, isWriterCustom) => {
    if (
      [Role.SuperAdmin, Role.Admin, Role.WriterFull].some(role =>
        groups.includes(role)
      )
    ) {
      return true;
    }

    return isWriterCustom;
  }
);

export {
  $isSuperAdmin,
  $isUploadAllowed,
  $isWriterCustom,
  $isCustomRole,
  $isNotAllowedToUseApp,
  $isAdmin
};
