import { Add as AddIcon } from '@mui/icons-material';

import { IconButton } from '@mui/material';

import {
  createManageDirectoryModel,
  ManageDirectoryTable
} from '@features/manage-directory';

import { $$session } from '@entities/session';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { DataTableFooter } from '@shared/ui';

const model = createManageDirectoryModel('function');

const Footer = createView()
  .units({
    dirty: model.$dirty,
    submitting: model.$submitting,
    onSubmit: model.submitClicked,
    onReset: model.resetClicked,
    onAddClick: model.addDirectoryClicked.prepend(trimPayload),
    editing: model.$editing,
    editable: $$session.$isAdmin,
    loading: model.$loading
  })
  .view(
    ({
      dirty,
      submitting,
      onSubmit,
      editing,
      editable,
      loading,
      onReset,
      onAddClick
    }) => {
      if (!editable) return null;

      return (
        <DataTableFooter
          isSubmitting={submitting}
          onSubmit={onSubmit}
          onReset={onReset}
          width={200}
          resetText='Reset'
          submitText='Save changes'
          hide={!dirty}
          disabledSubmit={editing}
        >
          <IconButton onClick={onAddClick} disabled={loading}>
            <AddIcon />
          </IconButton>
        </DataTableFooter>
      );
    }
  );

const FunctionPage = createView()
  .units({
    items: model.$directories,
    loading: model.$loading,
    temporaryExist: model.$temporaryExist,
    errorIds: model.$errorIds,
    touchedIds: model.$touchedIds,
    onRowChange: model.changed,
    editing: model.$editing,
    editable: $$session.$isAdmin,
    canDelete: model.$canDeleteExisting,
    onDeleteClick: model.deleteDirectoryClicked,
    onDeleteExistingClick: model.existingDirectoryDeleteClicked,
    onClearClick: model.clearDirectoryClicked,

    onEditStart: model.editStarted.prepend(trimPayload),
    onEditStop: model.editFinished.prepend(trimPayload)
  })
  .open(model.page.mounted)
  .close(model.page.unmounted)
  .view(
    ({
      editable,
      errorIds,
      items,
      editing,
      loading,
      canDelete,
      touchedIds,
      temporaryExist,
      onRowChange,
      onDeleteClick,
      onClearClick,
      onEditStart,
      onEditStop,
      onDeleteExistingClick
    }) => (
      <ManageDirectoryTable
        loading={loading}
        items={items}
        editing={editing}
        editable={editable}
        errorIds={errorIds}
        canDelete={canDelete}
        touchedIds={touchedIds}
        onRowChange={onRowChange}
        onDeleteClick={onDeleteClick}
        temporaryExist={temporaryExist}
        onClearClick={onClearClick}
        onCellEditStart={onEditStart}
        onCellEditStop={onEditStop}
        onDeleteExistingClick={onDeleteExistingClick}
        Footer={Footer}
      />
    )
  );

export { FunctionPage };
