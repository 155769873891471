import { useStore } from 'effector-react';

import type { FC, ReactNode } from 'react';

import { Router } from 'react-router-dom';

import { $router } from '@shared/history';

const WithRouter: FC<{ children: ReactNode }> = ({ children }) => {
  const { location, action, history } = useStore($router);

  return (
    <Router location={location} navigationType={action} navigator={history}>
      {children}
    </Router>
  );
};

export { WithRouter };
