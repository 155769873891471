import { Grid, TextField, Typography } from '@mui/material';

import type { ChangeEvent } from 'react';

import { createView } from '@shared/lib/view';

import { $accessKey, changed } from './model';

const AccessKeyStep = createView()
  .units({
    value: $accessKey,
    onChange: changed.prepend(getValueFromEvent)
  })
  .view(({ value, onChange }) => (
    <Grid container flex={1} justifyContent='center'>
      <Grid
        item
        container
        alignItems='center'
        flexDirection='column'
        gap={3}
        pt={3}
        xs={12}
        xl={6}
        lg={6}
        md={9}
      >
        <Typography>Put SharePoint Access-Key</Typography>

        <TextField
          label='Access Key'
          placeholder='Put SharePoint Access-Key'
          multiline
          rows={10}
          fullWidth
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  ));

function getValueFromEvent(
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) {
  return event.target.value;
}

export { AccessKeyStep };
