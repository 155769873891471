type DirectoryRoutes = {
  (): string;
  market: () => string;
  function: () => string;
};

const directoryRoutes: DirectoryRoutes = () => '/directories';
directoryRoutes.market = () => '/directories/market';
directoryRoutes.function = () => '/directories/function';

export { directoryRoutes };
