import type { Rule } from 'effector-forms/dist-types';

import type { AnySchema } from 'yup';

const createRule = <V, T = unknown>({
  schema,
  name
}: {
  schema: AnySchema<T>;
  name: string;
}): Rule<V> => ({
  name,
  validator: (value: V) => {
    try {
      schema.validateSync(value);

      return {
        isValid: true,
        value
      };
    } catch (err) {
      return {
        isValid: false,
        value,
        errorText: (err as { message: string }).message
      };
    }
  }
});

export { createRule };
