import { createEvent, sample } from 'effector';

import { sessionLib } from '@entities/session';

import { group } from '@shared/lib/effector-group';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { confirmEmailFx } from './api';

const reset = createEvent();

const emailPopulated = createEvent<string>();

const form = createForm({
  fields: {
    email: {
      init: '',
      rules: [sessionLib.validation.email]
    },
    code: {
      init: '',
      rules: [sessionLib.validation.code],
      filter: sessionLib.canEnterEmailCode
    }
  },

  resetOn: reset,
  blurValidatePreset: true
});

sample({
  clock: emailPopulated,
  fn: email => ({ email }),
  target: form.setForm
});

group('submit', () => {
  sample({
    clock: form.formValidated,
    target: confirmEmailFx
  });

  notification({
    clock: confirmEmailFx.fail,
    message: 'Wrong code',
    mode: 'error',
    http: true
  });

  notification({
    clock: confirmEmailFx.done,
    message: 'Email confirmed',
    mode: 'success'
  });
});

export { confirmEmailFx, form, reset, emailPopulated };
