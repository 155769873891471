import { ArrowBack } from '@mui/icons-material';

import { IconButton } from '@mui/material';

import { ChangePasswordForm } from '@features/auth/change-password';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { PageCenterLayout } from '@shared/ui';

import { confirmMFABackClicked } from '../model';

const ChangePasswordPage = createView()
  .units({ onBackClick: confirmMFABackClicked.prepend(trimPayload) })
  .view(({ onBackClick }) => (
    <PageCenterLayout
      before={
        <IconButton onClick={onBackClick}>
          <ArrowBack />
        </IconButton>
      }
    >
      <ChangePasswordForm />
    </PageCenterLayout>
  ));

export { ChangePasswordPage };
