import { Upload as UploadFileIcon } from '@mui/icons-material';

import { Button, styled } from '@mui/material';

import { useId } from '@shared/lib/hooks';

import { createView } from '@shared/lib/view';

import { validExtension } from './config';

import { getFilesFromEvent } from './lib';

import { fileInputChanged, unmounted } from './model';

const UploadFile = createView()
  .static({
    accept: validExtension.map(extension => `.${extension}`).join(', ')
  })
  .units({
    onChange: fileInputChanged.prepend(getFilesFromEvent)
  })
  .close(unmounted)
  .map(() => ({ id: useId() }))
  .view(({ id, accept, onChange }) => (
    <label htmlFor={id}>
      <Input onChange={onChange} accept={accept} id={id} multiple type='file' />

      <Button
        variant='contained'
        component='span'
        startIcon={<UploadFileIcon />}
      >
        Upload
      </Button>
    </label>
  ));

const Input = styled('input')({
  display: 'none'
});

export { UploadFile };
