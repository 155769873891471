import type { Store } from 'effector';

const createRouteStepper = (steps: string[], $pathname: Store<string>) => {
  const $activeStep = $pathname.map(path => {
    const step = steps.indexOf(path);

    return step !== -1 ? step : 0;
  });

  return $activeStep;
};

export { createRouteStepper };
