import type { FC } from 'react';

import '@processes/sign-up';

import '@processes/login';

import { mounted } from '@processes/ensure-start-on-login';

import { Pages } from '@pages';

import { createView } from '@shared/lib/view';

import { Provider } from './provider';

const App: FC = createView()
  .open(mounted)
  .view(() => (
    <Provider>
      <Pages />
    </Provider>
  ));

export { App };
