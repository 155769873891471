import type { Event, Store } from 'effector';

import { createEvent } from 'effector';

import { condition } from 'patronum';

const validation = <T>(options: {
  clock: Event<T>;
  validate: Store<boolean> | ((payload: T) => boolean);
}) => {
  const passed = createEvent<T>();
  const failed = createEvent<T>();

  condition({
    source: options.clock,
    if: options.validate,
    then: passed,
    else: failed
  });

  return { passed, failed };
};

export { validation };
