import { createEvent, sample } from 'effector';

import { $$login } from '@features/auth/login';

import { routes } from '@shared/config';

import { pushFx } from '@shared/history';

const confirmMFABackClicked = createEvent();
const registerLinkClicked = createEvent();

sample({
  clock: registerLinkClicked,
  target: $$login.reset
});

sample({
  clock: confirmMFABackClicked,
  target: pushFx.prepend(() => routes.auth.login())
});

export { registerLinkClicked, confirmMFABackClicked };
