import { createEvent, sample } from 'effector';

import { string } from 'yup';

import { sessionLib } from '@entities/session';

import { createRule } from '@shared/form';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { resetPasswordFx } from './api';

const reset = createEvent();

const form = createForm({
  fields: {
    session: {
      init: '',
      rules: [createRule({ name: 'session', schema: string().required() })]
    },

    email: {
      init: '',

      rules: [sessionLib.validation.email]
    },

    password: {
      init: '',

      rules: [sessionLib.validation.password]
    }
  },

  resetOn: reset,

  blurValidatePreset: true
});

sample({
  clock: form.formValidated,
  target: resetPasswordFx
});

//submit failed
notification({
  clock: resetPasswordFx.fail,

  message: 'Authentication error',

  mode: 'error',

  http: true
});

export { form, resetPasswordFx, reset };
