import { combine, createEvent, createStore, sample } from 'effector';

import { condition } from 'patronum';

import { createPage } from '@shared/lib/units';

import * as accessModel from './access-key/model';

import { steps } from './config';

import * as manageGridModel from './manage-grid/model';

import * as uploadLinksModel from './upload-links/model';

const page = createPage();

const nextClicked = createEvent();
const prevClicked = createEvent();

const $activeStep = createStore(0)
  .on(prevClicked, current => current - 1)
  .reset(page.unmounted);

const $isFirstStep = $activeStep.map(index => index === 0);
const $isLastStep = $activeStep.map(index => index === steps.length - 1);

const submitClicked = createEvent();
const manageReset = createEvent();

const $isValidStepData = combine(
  $activeStep,
  accessModel.$isValid,
  uploadLinksModel.$isValid,
  manageGridModel.manageFileMetaModel.$metasExist,
  (step, ...isValidSteps) => isValidSteps[step]
);

const transitionSucceed = sample({
  clock: nextClicked,
  filter: combine(
    $isValidStepData,
    $isLastStep,
    (isValid, isLastStep) => isValid && !isLastStep
  )
});
$activeStep.on(transitionSucceed, current => current + 1);

sample({
  source: submitClicked,
  target: manageGridModel.manageFileMetaModel.submitClicked
});

//prev
condition({
  source: prevClicked,
  if: $isLastStep,
  then: manageReset,
  else: uploadLinksModel.resetClicked
});

export {
  $isValidStepData,
  $activeStep,
  $isLastStep,
  $isFirstStep,
  page,
  submitClicked,
  nextClicked,
  prevClicked
};
