import { Outlet } from 'react-router';

import { routes } from '@shared/config';

import { createView } from '@shared/lib/view';

import type { TabsRouterTab } from '@shared/ui';

import { Container, TabsRouter } from '@shared/ui';

const tabs: TabsRouterTab[] = [
  {
    label: 'Market',
    route: routes.directories.market()
  },
  {
    label: 'Function',
    route: routes.directories.function()
  }
];

const DirectoryPage = createView().view(() => (
  <Container gap={3}>
    <TabsRouter tabs={tabs} />

    <Outlet />
  </Container>
));

export { DirectoryPage };
