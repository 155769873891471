import type { AxiosRequestConfig } from 'axios';

const addTokenToHeader = (config: AxiosRequestConfig = {}, token: string) => ({
  ...config,
  headers: {
    ...(config.headers ?? {}),
    Authorization: `Bearer ${token}`
  }
});

export { addTokenToHeader };
