import { ArrowBack } from '@mui/icons-material';

import { IconButton } from '@mui/material';

import { Outlet } from 'react-router';

import { routes } from '@shared/config';

import { $pathname } from '@shared/history';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { PageCenterLayout } from '@shared/ui';

import { backClicked, leave } from './model';

const $isConfirmEmailPage = $pathname.map(
  path => path === routes.auth.signUp.confirmEmail()
);

const SignUpLayout = createView()
  .units({
    isConfirmEmailPage: $isConfirmEmailPage,
    onBackClick: backClicked.prepend(trimPayload)
  })
  .close(leave)
  .view(({ onBackClick, isConfirmEmailPage }) => (
    <PageCenterLayout
      size={isConfirmEmailPage ? 500 : 700}
      before={
        <IconButton onClick={onBackClick}>
          <ArrowBack />
        </IconButton>
      }
    >
      <Outlet />
    </PageCenterLayout>
  ));

export { SignUpLayout };
