import { LoadingButton } from '@mui/lab';

import { Grid } from '@mui/material';

import { Field, Form } from '@shared/form';

import { createView } from '@shared/lib/view';

import { confirmEmailFx, form } from './model';

const $loading = confirmEmailFx.pending;
const $$fields = form.fields;

const ConfirmEmailForm = createView<{ timeout?: true }>()
  .units({ loading: $loading })

  .close(form.resetErrors)

  .view(({ timeout, loading }) => (
    <Form
      use={form}
      renderForm={({ onSubmit, onReset, children }) => (
        <Grid
          container
          item
          component='form'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          onSubmit={onSubmit}
          onReset={onReset}
          flex={1}
        >
          {children}
        </Grid>
      )}
    >
      <Grid
        item
        container
        flexDirection='column'
        justifyContent='center'
        gap={3}
        flex={1}
      >
        {timeout && (
          <Field.Text
            field={$$fields.email}
            type='email'
            autoComplete='email'
            label='Email'
            trimOnBlur
          />
        )}

        <Field.Number
          field={$$fields.code}
          label='Verification code'
          maxLength={6}
          positive
        />

        <LoadingButton
          type='submit'
          variant='contained'
          loading={loading}
          fullWidth
        >
          Confirm email
        </LoadingButton>
      </Grid>
    </Form>
  ));

export { ConfirmEmailForm };
