import { attach, createEvent, createStore, sample } from 'effector';

import {
  isCodeMismatchError,
  isNotAuthorizedError
} from '@features/auth/confirm-login-by-mfa/lib';

import { $$session, sessionLib } from '@entities/session';

import { group } from '@shared/lib/effector-group';

import { createPatchedForm as createForm } from '@shared/lib/form';

import { notification } from '@shared/notification';

import { getMFAQRFx, verifyMfaQRFx } from './api';

const dataPopulated = createEvent<{ email: string; session: string }>();
const reset = createEvent();

const $qr = createStore('');
const $session = createStore('');
const $email = createStore('');

const form = createForm({
  fields: {
    code: {
      init: '',
      rules: [sessionLib.validation.code],
      filter: sessionLib.canEnterEmailCode
    }
  },

  blurValidatePreset: true,
  resetOn: reset
});

group('preload', () => {
  $session.on(dataPopulated, (_, { session }) => session);
  $email.on(dataPopulated, (_, { email }) => email);
});

const { getQRFx } = group('load', () => {
  const getQRFx = attach({
    source: { email: $email, session: $session },
    effect: getMFAQRFx
  });

  $qr.on(getQRFx.doneData, (_, { data }) => data.qrcode);
  $session.on(getQRFx.doneData, (_, { data }) => data.session);

  return { getQRFx };
});

group('submit', () => {
  sample({
    clock: form.formValidated,
    source: { session: $session, email: $email },
    fn: ({ session, email }, { code }) => ({ session, email, totp: code }),
    target: verifyMfaQRFx
  });

  notification({
    clock: verifyMfaQRFx.failData,

    message: error =>
      isCodeMismatchError(error)
        ? 'Invalid verification code'
        : isNotAuthorizedError(error)
        ? 'Session is expired. Try login again'
        : 'Verify error',

    mode: 'error',

    http: true
  });

  sample({
    clock: verifyMfaQRFx.done,
    target: $$session.meFx
  });
});

export { getQRFx, verifyMfaQRFx, form, reset, $qr, dataPopulated };
