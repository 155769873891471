import { LoadingButton } from '@mui/lab';

import { Grid } from '@mui/material';

import type { PropsWithChildren } from 'react';

import { Field, Form } from '@shared/form';

import { createView } from '@shared/lib/view';

import { form, loginFx } from './model';

const $loading = loginFx.pending;
const $$fields = form.fields;

type LoginFormProps = PropsWithChildren<{}>;

const LoginForm = createView<LoginFormProps>()
  .units({ loading: $loading })

  .close(form.resetErrors)

  .view(({ loading, children }) => (
    <Form
      use={form}
      renderForm={({ onSubmit, children }) => (
        <Grid
          container
          item
          component='form'
          flexDirection='column'
          gap={3}
          onSubmit={onSubmit}
        >
          {children}
        </Grid>
      )}
    >
      <Field.Text
        field={$$fields.email}
        type='email'
        autoComplete='email'
        label='Email'
        trimOnBlur
      />

      <Field.Password field={$$fields.password} label='Password' />

      <Grid container gap={1.5} justifyContent='center'>
        <LoadingButton
          type='submit'
          variant='contained'
          loading={loading}
          fullWidth
        >
          Login
        </LoadingButton>

        {children}
      </Grid>
    </Form>
  ));

export { LoginForm };

export type { LoginFormProps };
