import { createEvent, sample } from 'effector';

import { routes } from '@shared/config';

import { pushFx } from '@shared/history';

import { group } from '@shared/lib/effector-group';

const backClicked = createEvent();
const leave = createEvent();
const confirmEmailAfterRefreshedClicked = createEvent();

group('go back', () => {
  sample({
    clock: backClicked,
    target: pushFx.prepend(() => routes.auth.login())
  });
});

export { backClicked, leave, confirmEmailAfterRefreshedClicked };
