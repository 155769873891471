import { LoadingButton } from '@mui/lab';

import { Grid } from '@mui/material';

import type { PropsWithChildren } from 'react';

import { Field, Form } from '@shared/form';

import { createView } from '@shared/lib/view';

import { form, signUpFx } from './model';

const $loading = signUpFx.pending;
const $$fields = form.fields;

const RegisterForm = createView<PropsWithChildren<{}>>()
  .units({ loading: $loading })

  .close(form.resetErrors)

  .view(({ loading, children }) => (
    <Form
      use={form}
      renderForm={({ onSubmit, onReset, children }) => (
        <Grid
          container
          item
          component='form'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          onSubmit={onSubmit}
          onReset={onReset}
          flex={1}
        >
          {children}
        </Grid>
      )}
    >
      <Grid
        item
        container
        flexDirection='column'
        justifyContent='center'
        gap={3}
        flex={1}
        width={500}
        pb={5}
      >
        <Field.Text field={$$fields.firstName} label='First Name' trimOnBlur />

        <Field.Text field={$$fields.lastName} label='Last Name' trimOnBlur />

        <Field.Text
          field={$$fields.email}
          type='email'
          autoComplete='email'
          label='Email'
          trimOnBlur
        />

        <Field.Password
          field={$$fields.password}
          autoComplete='new-password'
          label='Password'
        />

        <LoadingButton
          type='submit'
          variant='contained'
          loading={loading}
          fullWidth
        >
          Register
        </LoadingButton>

        {children}
      </Grid>
    </Form>
  ));

export { RegisterForm };
