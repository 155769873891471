import { Grid } from '@mui/material';

import { Field, Form } from '@shared/form';

import { createView } from '@shared/lib/view';

import type { StepperFooterFormControlsProps } from '@shared/ui';

import { StepperFooterFormControls } from '@shared/ui';

import { form, verifyMfaQRFx } from './model';

const $verifying = verifyMfaQRFx.pending;
const $dirty = form.$isDirty;
const $$fields = form.fields;

type SetupMFAFormProps = Omit<
  Partial<StepperFooterFormControlsProps>,
  'loading' | 'dirty'
>;

const SetupMFAForm = createView<SetupMFAFormProps>()
  .units({
    loading: $verifying,
    dirty: $dirty
  })

  .close(form.resetErrors)

  .view(({ loading, dirty, ...props }) => (
    <Form
      use={form}
      renderForm={({ onSubmit, onReset, children }) => (
        <Grid
          container
          item
          component='form'
          flexDirection='column'
          alignItems='center'
          justifyContent='space-between'
          onSubmit={onSubmit}
          onReset={onReset}
          flex={1}
        >
          {children}
        </Grid>
      )}
    >
      <Grid
        item
        container
        flexDirection='column'
        justifyContent='center'
        gap={3}
        flex={1}
        width={500}
        pb={5}
      >
        <Field.Number
          field={$$fields.code}
          label='Verification code'
          maxLength={6}
          positive
        />
      </Grid>

      <StepperFooterFormControls
        submitText='Confirm MFA'
        loading={loading}
        dirty={dirty}
        {...props}
      />
    </Form>
  ));

export { SetupMFAForm };
