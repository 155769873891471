import { combine, createStore } from 'effector';

import type { FunctionDto, MarketDto } from '@shared/api';

import { mapBy, uniqueBy } from '@shared/lib/prototype';

import {
  getAllowedFunctionsFx,
  getAllowedMarketsFx,
  getDirectoriesFx,
  updateDirectoriesFx
} from './api';

const $markets = createStore<MarketDto[]>([]).on(
  getDirectoriesFx.doneData,
  (_, { data: { market } }) => uniqueBy(market, 'name')
);
const $functions = createStore<FunctionDto[]>([]).on(
  getDirectoriesFx.doneData,
  (_, { data }) => uniqueBy(data.function, 'name')
);

const $allowedMarkets = createStore<MarketDto[]>([]).on(
  getAllowedMarketsFx.doneData,
  (_, { data }) => uniqueBy(data, 'name')
);
const $allowedFunctions = createStore<FunctionDto[]>([]).on(
  getAllowedFunctionsFx.doneData,
  (_, { data }) => uniqueBy(data, 'name')
);

const $allowedMarketNames = $allowedMarkets.map(markets =>
  mapBy(markets, 'name')
);
const $allowedFunctionNames = $allowedFunctions.map(functions =>
  mapBy(functions, 'name')
);

const $allowedDirectories = combine({
  market: $allowedMarkets,
  function: $allowedFunctions
});

export {
  $markets,
  $functions,
  $allowedDirectories,
  $allowedFunctionNames,
  $allowedMarketNames,
  $allowedFunctions,
  $allowedMarkets,
  getDirectoriesFx,
  updateDirectoriesFx,
  getAllowedFunctionsFx,
  getAllowedMarketsFx
};
