import { attach, combine, createEvent, createStore } from 'effector';

import type { Action, BrowserHistory, Location, Update } from 'history';

import { createBrowserHistory } from 'history';

type Url = Parameters<BrowserHistory['push']>[0];

const history = createBrowserHistory();
const setUpdate = createEvent<Update>();

const $history = createStore<BrowserHistory>(history);
const $location = createStore<Location>(history.location);
const $action = createStore<Action>(history.action);

history.listen(setUpdate);

$location.on(setUpdate, (_, { location }) => location);
$action.on(setUpdate, (_, { action }) => action);

const pushFx = attach({
  source: $history,
  effect: (history, url: Url) => history.push(url)
});

const $router = combine({
  history: $history,
  location: $location,
  action: $action
});

const $pathname = $location.map(({ pathname }) => pathname);

export { pushFx, $router, $history, $location, $action, $pathname };
