import { attach } from 'effector';

import { api } from '@shared/api/api';

const getMFAQRFx = attach({
  effect: api.auth.getMfaToken
});
const verifyMfaQRFx = attach({
  effect: api.auth.verifyMfaToken
});

export { getMFAQRFx, verifyMfaQRFx };
