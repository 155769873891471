import { combine, createEvent, createStore, sample } from 'effector';

import { reset } from 'patronum';

import { $$directory } from '@entities/directories';

import type { FileMeta, FulfilledFileMeta } from '@entities/file';

import { routes } from '@shared/config';

import { pushFx } from '@shared/history';

import { hashMap } from '@shared/lib/hash-map';

import { emptyArray, notEmptyArray } from '@shared/lib/prototype';

import { repository } from '@shared/lib/repository';

import { createEffectResult, createPage, validation } from '@shared/lib/units';

import { notification } from '@shared/notification';

import {
  arrayConcatToString,
  getUnfulfilledKeys,
  getUnfulfilledMetas,
  isDuplicateExplorerPath,
  isServiceDateValid,
  isStringValid
} from './lib';

type CreateManageFileMetaOptions = {
  skipValidationFieldChecks: boolean;
};

const defaultCreateManageFileMetaOptions: CreateManageFileMetaOptions = {
  skipValidationFieldChecks: false
};

const createManageFileMeta = (options = defaultCreateManageFileMetaOptions) => {
  const page = createPage();

  const metaLoaded = createEvent<FileMeta[]>();
  const metaChanged = createEvent<FileMeta>();
  const metaDeleted = createEvent<FileMeta['id']>();

  const filesMetaUploaded = createEvent();

  const submitClicked = createEvent();
  const resetClicked = createEvent();

  const singleMetaUpload = createEffectResult<{
    fileName: string;
    id: FileMeta['id'];
    isDuplicationError?: boolean;
  }>();

  const editStarted = createEvent();
  const editFinished = createEvent();

  const $editing = createStore(false)
    .on(editStarted, () => true)
    .reset(editFinished);

  const submitted = createEvent<FulfilledFileMeta[]>();

  const $metas = createStore<FileMeta[]>([]);
  const $submitting = createStore(false);
  const $touched = createStore(false).on(submitClicked, () => true);

  //load
  sample({
    clock: page.mounted,
    target: [$$directory.getAllowedFunctionsFx, $$directory.getAllowedMarketsFx]
  });

  $metas.on(metaLoaded, (oldMeta, newMeta) => [...oldMeta, ...newMeta]);

  //delete item

  $metas.on(metaDeleted, (metas, fileId) =>
    metas.filter(meta => meta.id !== fileId)
  );

  //reset

  $metas.reset(resetClicked);
  $touched.on(resetClicked, () => false);

  //change
  $metas.on(metaChanged, (metas, newMeta) =>
    repository.put(metas, {
      ...newMeta,
      name: newMeta.name.trim(),
      from: newMeta.from.trim()
    })
  );

  //submit
  $submitting.on(submitClicked, () => true);

  const $metasExist = $metas.map(notEmptyArray);
  const $unfulfilledMetas = $metas.map(metas =>
    getUnfulfilledMetas(metas, options.skipValidationFieldChecks)
  );

  const allFulfilledValidation = validation({
    clock: sample({
      clock: submitClicked,
      source: $metas,
      filter: $metasExist
    }),
    validate: $unfulfilledMetas.map(emptyArray)
  });

  const $unfulfilledMetasKeys = $unfulfilledMetas.map(metas =>
    metas.map(meta => ({
      meta,
      fields: getUnfulfilledKeys(meta, options.skipValidationFieldChecks)
    }))
  );

  notification({
    clock: allFulfilledValidation.failed,
    message: `Please fill in all fields of the form`,
    mode: 'error'
  });

  const $invalidFromMetas = $metas.map(metas =>
    metas.filter(meta => meta.name.length >= 255)
  );

  const fromValidation = validation({
    clock: allFulfilledValidation.passed,
    validate: $invalidFromMetas.map(emptyArray)
  });

  notification({
    clock: fromValidation.failed,
    message: "Max length of 'File Name' equal 255",
    mode: 'error'
  });

  const $invalidFileNameMetas = $metas.map(metas =>
    metas.filter(meta => !isStringValid(meta.name))
  );

  const fileNameValidation = validation({
    clock: fromValidation.passed,
    validate: $invalidFileNameMetas.map(emptyArray)
  });

  notification({
    clock: fileNameValidation.failed,
    message: "Field 'File Name' contains spec or non-latin chars",
    mode: 'error'
  });

  const $invalidFileNameLengthMetas = $metas.map(metas =>
    metas.filter(meta => meta.name.length >= 255)
  );

  const fileNameLengthValidation = validation({
    clock: fileNameValidation.passed,
    validate: $invalidFileNameMetas.map(emptyArray)
  });

  notification({
    clock: fileNameLengthValidation.failed,
    message: "Max length of 'File Name' equal 255",
    mode: 'error'
  });

  const $invalidServiceDates = $metas.map(metas =>
    metas.filter(meta => !isServiceDateValid(meta))
  );

  const dateValidation = validation({
    clock: fileNameLengthValidation.passed,
    validate: $invalidServiceDates.map(emptyArray)
  });

  notification({
    clock: dateValidation.failed,
    source: $invalidServiceDates,
    message: invalidMetas => {
      const names = arrayConcatToString(
        invalidMetas,
        meta => ` "${meta.name}"`
      );

      return `Invalid "Service Month" for rows with filename: ${names}.`;
    },
    mode: 'error'
  });

  const $duplicateExplorerPathRows = $metas.map(metas =>
    metas.filter(meta => isDuplicateExplorerPath(metas, meta))
  );

  const uniqueValidation = validation({
    clock: dateValidation.passed,
    validate: $duplicateExplorerPathRows.map(emptyArray)
  });

  notification({
    clock: uniqueValidation.failed,
    source: $duplicateExplorerPathRows,
    message: invalidMetas => {
      const names = arrayConcatToString(
        [...new Set(invalidMetas.map(({ name }) => name))],
        name => ` "${name}"`
      );

      return `Duplicated rows with filename: ${names}.`;
    },
    mode: 'error'
  });

  $submitting.reset([
    dateValidation.failed,
    allFulfilledValidation.failed,
    uniqueValidation.failed,
    fromValidation.failed,
    fileNameValidation.failed,
    fileNameLengthValidation.failed
  ]);

  sample({
    clock: uniqueValidation.passed,
    filter: (metas): metas is FulfilledFileMeta[] => !!metas,
    target: submitted
  });

  //submit result happened
  notification({
    clock: singleMetaUpload.fail,
    message: ({ fileName, isDuplicationError }) =>
      isDuplicationError
        ? `File "${fileName}" not uploaded, because it already exist`
        : `File "${fileName}" not uploaded`,
    mode: 'error'
  });

  notification({
    clock: singleMetaUpload.done,
    message: ({ fileName }) => `File "${fileName}" uploaded`,
    mode: 'success'
  });

  const metaAfterUploadFiltered = sample({
    clock: singleMetaUpload.done,
    source: $metas,
    fn: (metas, { id }) => repository.remove(metas, id)
  });

  $metas.on(metaAfterUploadFiltered, (_, filteredMeta) => filteredMeta);

  const submitReseed = sample({
    clock: metaAfterUploadFiltered,
    filter: filtered => notEmptyArray(filtered)
  });

  $touched.on(submitReseed, () => false);

  $submitting.reset(filesMetaUploaded);

  sample({
    clock: filesMetaUploaded,
    source: $metas,
    filter: metas => metas.length === 0,
    target: pushFx.prepend(() => routes.bucket())
  });

  reset({
    clock: page.unmounted,
    target: [$metas, $submitting, $touched, $editing]
  });

  //selectors
  const $unfulfilledMetaMap = $unfulfilledMetasKeys.map(metas =>
    hashMap(
      metas,
      item => item,
      item => item.meta.id
    )
  );

  const $serviceMonthErrorMap = $invalidServiceDates.map(metas =>
    hashMap(metas, item => item)
  );

  const $duplicateExplorerPathRowsMap = $duplicateExplorerPathRows.map(metas =>
    hashMap(metas, item => item)
  );

  const $invalidFromMetasMap = $invalidFromMetas.map(metas =>
    hashMap(metas, item => item)
  );

  const $invalidFileNameMetasMap = combine(
    $invalidFileNameMetas,
    $invalidFileNameLengthMetas,
    (names, length) => [...names, ...length]
  ).map(metas => hashMap(metas, item => item));

  return {
    //need connect
    metaLoaded,
    submitted,
    filesMetaUploaded,
    singleMetaUpload,
    //

    $metas,
    $editing,
    $touched,
    $submitting,
    $metasExist,
    $unfulfilledMetaMap,
    $serviceMonthErrorMap,
    $unfulfilledMetasKeys,
    $invalidFileNameMetasMap,
    $duplicateExplorerPathRowsMap,
    $invalidFromMetasMap,
    page,
    resetClicked,
    metaChanged,
    deleteClicked: metaDeleted,
    submitClicked,
    editStarted,
    editFinished
  };
};

export { createManageFileMeta };
