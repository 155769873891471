import { Select } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';

import type { SelectChangeEvent, SelectProps } from '@mui/material/Select';

import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/material/utils';

import type {
  GridRenderEditCellParams,
  GridValueFormatterParams,
  ValueOptions
} from '@mui/x-data-grid';

import {
  GridEditModes,
  useGridApiContext,
  useGridRootProps
} from '@mui/x-data-grid';

import { isEscapeKey } from '@mui/x-data-grid/utils/keyboardUtils';

import React from 'react';

const renderSingleSelectOptions = (
  option: ValueOptions,
  OptionComponent: React.ElementType
) => {
  const isOptionTypeObject = typeof option === 'object';

  const key = isOptionTypeObject ? option.value : option;
  const value = isOptionTypeObject ? option.value : option;
  const content = isOptionTypeObject ? option.label : option;

  return (
    <OptionComponent key={key} value={value}>
      {content}
    </OptionComponent>
  );
};

type GridEditSingleSelectCellProps = {
  /**
   * Callback called when the value is changed by the user.
   * @param {SelectChangeEvent<any>} event The event source of the callback.
   * @param {any} newValue The value that is going to be passed to `apiRef.current.setEditCellValue`.
   * @returns {Promise<void> | void} A promise to be awaited before calling `apiRef.current.setEditCellValue`
   */
  onValueChange?: (
    event: SelectChangeEvent<unknown>,
    newValue: unknown
  ) => Promise<void> | void;
} & GridRenderEditCellParams &
  Omit<SelectProps, 'id' | 'tabIndex' | 'value'>;

const GridEditMultipleSelectCell = (props: GridEditSingleSelectCellProps) => {
  const {
    id,
    value,
    formattedValue,
    api,
    field,
    row,
    rowNode,
    colDef,
    cellMode,
    isEditable,
    tabIndex,
    className,
    getValue,
    hasFocus,
    isValidating,
    isProcessingProps,
    error,
    onValueChange,
    ...other
  } = props;

  const apiRef = useGridApiContext();
  const ref = React.useRef<unknown>();
  const inputRef = React.useRef<HTMLInputElement>();
  const rootProps = useGridRootProps();
  const [open, setOpen] = React.useState(rootProps.editMode === 'cell');

  const baseSelectProps = rootProps.componentsProps?.baseSelect || {};
  const isSelectNative = baseSelectProps.native ?? false;

  let valueOptionsFormatted: ValueOptions[];

  if (typeof colDef.valueOptions === 'function') {
    valueOptionsFormatted = colDef.valueOptions({ id, row, field });
  } else {
    valueOptionsFormatted = colDef.valueOptions ?? [];
  }

  if (colDef.valueFormatter) {
    valueOptionsFormatted = valueOptionsFormatted.map(option => {
      if (typeof option === 'object') {
        return option;
      }

      const params: GridValueFormatterParams = { field, api, value: option };

      return {
        value: option,
        label: String(colDef.valueFormatter?.(params))
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const onChange: SelectProps['onChange'] = async event => {
    const target = event.target as HTMLInputElement; // NativeSelect casts the value to a string.

    //NOTE: real type is Array
    const formattedTargetValue = target.value;

    if (onValueChange) {
      await onValueChange(event, formattedTargetValue);
    }

    await apiRef.current.setEditCellValue(
      { id, field, value: formattedTargetValue },
      event
    );
  };

  const onClose = (event: React.KeyboardEvent, reason: string) => {
    if (rootProps.editMode === GridEditModes.Row) {
      setOpen(false);

      return;
    }

    if (!(reason === 'backdropClick' || isEscapeKey(event.key))) return;

    apiRef.current.stopCellEditMode({
      id,
      field
    });
  };

  const onOpen = () => {
    setOpen(true);
  };

  useEnhancedEffect(() => {
    if (!hasFocus || !inputRef.current) return;

    inputRef.current?.focus?.();
  }, [hasFocus]);

  return (
    <Select
      ref={ref}
      inputRef={inputRef}
      value={(Array.isArray(value)
        ? value
        : typeof value === 'string'
        ? value.replaceAll(' ', '').split(',')
        : [value]
      ).filter(Boolean)}
      onChange={onChange}
      open={open}
      onOpen={onOpen}
      MenuProps={{
        onClose: onClose
      }}
      error={error}
      native={isSelectNative}
      fullWidth
      multiple
      {...other}
      {...rootProps.componentsProps?.baseSelect}
    >
      {valueOptionsFormatted.map(valueOptions =>
        renderSingleSelectOptions(
          valueOptions,
          isSelectNative ? 'option' : MenuItem
        )
      )}
    </Select>
  );
};

const renderEditMultipleSelectCell = (
  params: GridEditSingleSelectCellProps
) => <GridEditMultipleSelectCell {...params} />;

export { GridEditMultipleSelectCell, renderEditMultipleSelectCell };

export type { GridRenderEditCellParams };
