import { useCallback, useState } from 'react';

const useOpen = (defaultValue = false) => {
  const [isOpen, setOpen] = useState(defaultValue);

  const toggle = useCallback(() => {
    setOpen(open => !open);
  }, []);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(true);
  }, []);

  return { isOpen, toggle, open, close };
};

export { useOpen };
