type SignUpRoutes = {
  (): string;
  register: () => string;
  confirmEmail: () => string;
  confirmEmailAfterTimeout: () => string;
};

const signUpRoutes: SignUpRoutes = () => '/sign-up';
signUpRoutes.register = () => '/sign-up/register';
signUpRoutes.confirmEmail = () => '/sign-up/confirm-email';
signUpRoutes.confirmEmailAfterTimeout = () => `/sign-up/confirm-email/timeout`;

export { signUpRoutes };
