function hashMap<
  Source,
  MapItem = Source,
  MapKey extends string = Source extends { id: string } ? Source['id'] : string
>(
  source: Source[],
  getMapItem: (item: Source) => MapItem,
  getMapKey?: MapKey | ((item: Source) => MapKey)
) {
  const map = {} as Record<string, MapItem>;

  source.forEach(item => {
    const mapKey =
      typeof getMapKey === 'function' ? getMapKey(item) : getMapKey;

    const key = mapKey ?? (item as Source & { id: string }).id;

    map[key] = getMapItem(item);
  });

  return map as Record<MapKey, MapItem>;
}

export { hashMap };
