import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';

import { Grid, keyframes, styled, Typography, useTheme } from '@mui/material';

import { createView } from '@shared/lib/view';

import { manageMetaModel } from './model';

type TableDropZoneAreaProps = {
  isDragActive: boolean;
  isDragAccept: boolean;
};

const TableDropZoneArea = createView<TableDropZoneAreaProps>()
  .units({ noClick: manageMetaModel.$metasExist })
  .map(({ noClick, isDragActive }) => ({
    palette: useTheme().palette,
    zIndex: noClick && !isDragActive ? 0 : 6,
    opacity: noClick && !isDragActive ? 0 : 1
  }))
  .view(({ isDragActive, isDragAccept, zIndex, opacity, palette }) => (
    <Grid
      container
      position='absolute'
      alignItems='center'
      justifyContent='center'
      height='100%'
      width='100%'
      p={1}
      zIndex={zIndex}
      sx={{ background: 'white', opacity }}
    >
      <Grid
        item
        container
        alignItems='center'
        justifyContent='center'
        direction='column'
        gap={3}
        height='100%'
        width='100%'
        sx={
          isDragActive
            ? isDragAccept
              ? getRepeatingBgAnimationStyles(
                  palette.background.paper,
                  palette.divider,
                  palette.primary.light,
                  true
                )
              : getRepeatingBgAnimationStyles(
                  palette.error.light,
                  palette.error.dark,
                  palette.error.main
                )
            : {
                border: 'dashed',
                borderColor: palette.divider
              }
        }
      >
        <Grid item>
          <Typography variant='h4'>
            {isDragActive ? (
              <>{isDragAccept ? 'Drop file here' : 'Invalid file type'}</>
            ) : (
              <>Drag and drop file here or click to upload.</>
            )}
          </Typography>
        </Grid>

        <Grid item>
          <StyledCloudUploadIcon />
        </Grid>
      </Grid>
    </Grid>
  ));

const StyledCloudUploadIcon = styled(CloudUploadIcon)`
  width: 51px;
  height: 51px;
`;

const uploadKeyframes = keyframes`
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -70px 0;
  }
`;

function getRepeatingBgAnimationStyles(
  primary: string,
  secondary: string,
  borderColor: string,
  solid = false
) {
  const base = {
    backgroundImage: `repeating-linear-gradient(-45deg, ${primary}, ${primary} 25px, ${secondary} 25px, ${secondary} 50px)`,
    backgroundSize: '150% 100%',
    borderColor,
    animation: `${uploadKeyframes} 2s linear infinite !important`
  };

  return solid ? base : { ...base, border: 'solid' };
}

export { TableDropZoneArea };
