import { merge, sample } from 'effector';

import { $$changePassword } from '@features/auth/change-password';

import { $$confirmLoginByMFA } from '@features/auth/confirm-login-by-mfa';

import { $$login } from '@features/auth/login';

import { $$setupMFA } from '@features/auth/setup-mfa';

import { routes } from '@shared/config';

import { pushFx } from '@shared/history';

import { group } from '@shared/lib/effector-group';

group('MFA_SETUP', () => {
  const logged = $$login.loginFx.done.map(({ result, params }) => ({
    challengeName: result.data.challengeName,
    email: params.email,
    session: result.data.session!
  }));

  const passwordChanged = $$changePassword.resetPasswordFx.done.map(
    ({ result, params }) => ({
      challengeName: result.data.challengeName,
      email: params.email,
      session: result.data.session!
    })
  );

  const challengeProducerTriggered = merge([passwordChanged, logged]);

  sample({
    clock: challengeProducerTriggered,
    filter: ({ challengeName }) => challengeName === 'MFA_SETUP',
    fn: ({ email, session }) => ({ email, session }),
    target: [
      $$setupMFA.dataPopulated,
      pushFx.prepend(() => routes.auth.mfa.setup())
    ]
  });
});

group('NEW_PASSWORD_REQUIRED', () => {
  const newPasswordRequiredMatched = sample({
    clock: $$login.loginFx.doneData,
    filter: ({ data: { challengeName } }) =>
      challengeName === 'NEW_PASSWORD_REQUIRED'
  });

  sample({
    clock: newPasswordRequiredMatched,
    source: $$login.form.$values,
    fn: ({ email }, { data: { session } }) => ({ email, session }),
    target: $$changePassword.form.setForm
  });

  sample({
    clock: newPasswordRequiredMatched,
    target: pushFx.prepend(() => routes.auth.login.changePassword())
  });
});

group('SOFTWARE_TOKEN_MFA', () => {
  const softwareTokenMFAMatched = sample({
    clock: $$login.loginFx.doneData,
    filter: ({ data: { challengeName } }) =>
      challengeName === 'SOFTWARE_TOKEN_MFA'
  });

  sample({
    clock: softwareTokenMFAMatched,
    source: $$login.form.$values,
    fn: ({ email }, { data: { session } }) => ({ email, session }),
    target: $$confirmLoginByMFA.dataPopulated
  });

  sample({
    clock: softwareTokenMFAMatched,
    target: pushFx.prepend(() => routes.auth.login.mfa())
  });
});
