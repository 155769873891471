import {
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';

import {
  Breadcrumbs,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Slide,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';

import type { TransitionProps } from '@mui/material/transitions';

import type {
  DataGridProps,
  GridCellParams,
  GridEnrichedColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel
} from '@mui/x-data-grid';

import {
  getGridStringOperators,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef
} from '@mui/x-data-grid';

import { useGate } from 'effector-react';

import { nanoid } from 'nanoid';

import type { ReactElement, Ref } from 'react';

import React, { forwardRef, memo } from 'react';

import { trimPayload } from '@shared/lib/prototype';

import { createView } from '@shared/lib/view';

import { EnhancedDataGrid, NoRowsOverlay } from '@shared/ui';

import {
  $$deleteFile,
  $breadcrumbLevels,
  $isExplorerMode,
  $isFilesColumnsShow,
  $isSomeFileSelected,
  $items,
  $level,
  $loading,
  $mode,
  ApiRefGate,
  breadcrumbsClicked,
  cellDoubleClicked,
  downloadFileClicked,
  downloadSelectedFiles,
  enter,
  exit,
  modeChanged,
  refreshClicked,
  rowClicked,
  selectionChanged
} from './bucket.model';

import { breadcrumbsLevels, fileMetaColumns } from './config';

import type { DocumentInfo } from './types';

const BucketPage = createView()
  .units({
    level: $level.map(level => level + 1),
    loading: $loading,

    isFilesColumnsShow: $isFilesColumnsShow,
    onSelectionModelChange: selectionChanged.prepend(
      (model: GridSelectionModel) => model as string[]
    ),
    onCellDoubleClick: cellDoubleClicked.prepend(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (params: GridCellParams<any, any, any>) => {
        //FIXME
        if (GRID_CHECKBOX_SELECTION_COL_DEF.field == params.field) {
          return '';
        }

        return getFieldValue(params);
      }
    )
  })
  .open(enter)
  .close(exit)

  .view(
    ({
      level,
      loading,
      isFilesColumnsShow,
      onSelectionModelChange,
      onCellDoubleClick
    }) => (
      <Grid flex={1} pb={2}>
        <EnhancedDataGridWithData
          loading={loading}
          columns={isFilesColumnsShow ? fileColumns : dirColumns(level)}
          getRowId={getRowId}
          components={components}
          disableSelectionOnClick
          checkboxSelection={isFilesColumnsShow}
          onSelectionModelChange={onSelectionModelChange}
          onCellDoubleClick={onCellDoubleClick}
        />

        <DeleteDialog />
      </Grid>
    )
  );

const EnhancedDataGridWithData = memo(
  createView<Omit<DataGridProps<DocumentInfo>, 'rows' | 'onRowClick'>>()
    .units({
      rows: $items,
      onRowClick: rowClicked.prepend(({ row }: GridRowParams) => row)
    })
    .map(() => ({ apiRef: useGridApiRef() }))
    .effect(({ apiRef }) => {
      useGate(ApiRefGate, apiRef);
    })
    .view(props => <EnhancedDataGrid<DocumentInfo> {...props} />)
);

const dirColumns = (level: number) => [
  {
    headerName: capitalize(breadcrumbsLevels[level] ?? 'Name'),
    type: 'string',
    field: 'name'
  }
];

const fileColumns = [
  ...fileMetaColumns.map(
    (def): GridEnrichedColDef<DocumentInfo> => ({
      ...def,
      renderCell: renderCell,
      align: 'left',
      headerAlign: 'left',
      filterOperators: getGridStringOperators().filter(
        operator => operator.value !== 'isAnyOf'
      )
    })
  ),
  {
    type: 'actions',
    field: 'ACTIONS',
    renderHeader: renderHeaderDownloadSelectedButton,
    getActions: tableActions
  }
];

function renderCell(
  params: GridRenderCellParams<string, DocumentInfo, string>
) {
  return <GridCellWithTooltip text={getFieldValue(params)} />;
}

const GridCellWithTooltip = memo(
  createView<{ text: string }>().view(({ text }) => (
    <Tooltip title={text} placement='bottom-start'>
      <span>{text}</span>
    </Tooltip>
  ))
);

function renderHeaderDownloadSelectedButton() {
  return <DownloadSelectedButton />;
}

function tableActions({ row }: GridRowParams<DocumentInfo>) {
  return [
    <DownloadRowAction key='download' row={row} />,
    <DeleteRowAction key='delete' row={row} />
  ];
}

const DownloadRowAction = memo(
  createView<{ row: DocumentInfo }>()
    .units({ onDeleteClick: downloadFileClicked })
    .view(({ row, onDeleteClick }) => (
      <GridActionsCellItem
        key='Download'
        label='Download'
        icon={<DownloadIcon />}
        onClick={() => onDeleteClick(row)}
      />
    ))
);

const DeleteRowAction = memo(
  createView<{ row: DocumentInfo }>()
    .units({
      hidden: $$deleteFile.$isSomeDocsCanBeDeleted.map(is => !is),
      loading: $$deleteFile.$pending,
      onDeleteClick: $$deleteFile.deleteClicked
    })
    .view(({ hidden, loading, row, onDeleteClick }) => {
      if (hidden) return null;

      return (
        <GridActionsCellItem
          key='Delete'
          label='Delete'
          icon={<DeleteIcon />}
          disabled={!row.canDelete || loading}
          onClick={() => onDeleteClick(row)}
        />
      );
    })
);

const DownloadSelectedButton = memo(
  createView()
    .units({
      isSomeFileSelected: $isSomeFileSelected,
      onDownloadSelectedClick: downloadSelectedFiles
    })
    .view(({ isSomeFileSelected, onDownloadSelectedClick }) => {
      if (!isSomeFileSelected) return null;

      return (
        <IconButton onClick={() => onDownloadSelectedClick()}>
          <DownloadIcon />
        </IconButton>
      );
    })
);

function getRowId(row: DocumentInfo) {
  return row?.id ?? row.name ?? nanoid();
}

const Toolbar = memo(
  createView()
    .units({ isExplorer: $isExplorerMode })
    .view(({ isExplorer }) => (
      <GridToolbarContainer>
        <Grid container justifyContent='space-between' p={1} gap={1}>
          <Grid item alignSelf='center'>
            {isExplorer ? (
              <ExplorerBreadcrumbs />
            ) : (
              <Typography color='text.primary'>List</Typography>
            )}
          </Grid>

          <Grid container gap={2} alignItems='center' justifyContent='flex-end'>
            <ToolbarActions />
          </Grid>
        </Grid>
      </GridToolbarContainer>
    ))
);

const ToolbarActions = memo(
  createView().view(() => (
    <>
      <TableModeSwitcher />

      <GridToolbarRefreshDataButton />

      <GridToolbarColumnsButton />

      <GridToolbarFilterButton />

      <GridToolbarDensitySelector />

      <GridToolbarQuickFilter />
    </>
  ))
);

const ExplorerBreadcrumbs = memo(
  createView()
    .units({
      levels: $breadcrumbLevels,
      onBreadcrumbsClick: breadcrumbsClicked
    })
    .view(({ levels, onBreadcrumbsClick }) => (
      <Breadcrumbs>
        {levels.map(({ level, name }, index) => {
          if (index === levels.length - 1) {
            return (
              <Typography key={level} color='text.primary'>
                {name}
              </Typography>
            );
          }

          return (
            <Link
              key={level}
              underline='hover'
              color='inherit'
              href='#'
              onClick={() => onBreadcrumbsClick(level)}
            >
              {name}
            </Link>
          );
        })}
      </Breadcrumbs>
    ))
);

const TableModeSwitcher = memo(
  createView()
    .units({ mode: $mode, onSwitch: modeChanged })
    .view(({ mode, onSwitch }) => (
      <Grid
        component='label'
        container
        width='unset'
        alignItems='center'
        spacing={1}
      >
        <Grid item>List</Grid>

        <Grid item>
          <Switch
            checked={mode === 'explorer'} // relevant state for your case
            onChange={() => onSwitch()} // relevant method to handle your change
            value='explorer'
          />
        </Grid>

        <Grid item>Explorer</Grid>
      </Grid>
    ))
);

const GridToolbarRefreshDataButton = memo(
  createView()
    .units({ onClick: refreshClicked })
    .view(({ onClick }) => (
      <Button
        onClick={() => onClick()}
        size='small'
        startIcon={<RefreshIcon />}
      >
        Refresh
      </Button>
    ))
);

const components = {
  Toolbar: memo(Toolbar),
  LoadingOverlay: memo(LinearProgress),
  NoRowsOverlay: memo(NoRowsOverlay)
};

function getFieldValue(params: { formattedValue?: string; value?: string }) {
  return params.formattedValue ?? params.value ?? '';
}

const DeleteDialog = createView()
  .units({
    open: $$deleteFile.$dialogOpened,
    loading: $$deleteFile.$pending,
    onClose: $$deleteFile.dialogClosed.prepend(trimPayload),
    onConfirm: $$deleteFile.confirmed.prepend(trimPayload)
  })
  .view(({ open, loading, onConfirm, onClose }) => (
    <Dialog open={open} TransitionComponent={Transition} onClose={onClose}>
      <DialogTitle textAlign='center'>Are you sure?</DialogTitle>

      <DialogActions>
        <Button size='large' onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          color='primary'
          size='large'
          onClick={onConfirm}
          loading={loading}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  ));

const Transition = forwardRef(
  (
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
  ) => (
    <Slide direction='up' ref={ref} {...props}>
      {props.children}
    </Slide>
  )
);

export { BucketPage };
