import { ArrowBack, ArrowForward, Clear } from '@mui/icons-material';

import { LoadingButton } from '@mui/lab';

import { Button, Grid } from '@mui/material';

import { createView } from '@shared/lib/view';

type StepperFooterFormControlsProps = {
  onGoBackClick?: () => void;

  dirty?: boolean;
  loading?: boolean;

  submitText?: string;
  clearText?: string;
  backText?: string;

  submitButtonWidth?: number;
  clearButtonWidth?: number;
  backButtonWidth?: number;

  disableHorizontalPaddings?: boolean;
  disabledBottomPaddings?: boolean;
};

const StepperFooterFormControls = createView<StepperFooterFormControlsProps>()
  .defaultProps({
    submitText: 'Submit',
    backText: 'Back',
    clearText: 'Clear',
    submitButtonWidth: 200,
    backButtonWidth: 150,
    clearButtonWidth: 200
  })
  .view(
    ({
      dirty,
      loading,
      onGoBackClick,
      clearText,
      backText,
      submitText,
      clearButtonWidth,
      submitButtonWidth,
      backButtonWidth,
      disableHorizontalPaddings,
      disabledBottomPaddings
    }) => (
      <Grid
        item
        container
        justifyContent='space-between'
        gap={1}
        px={disableHorizontalPaddings ? 0 : 3}
        py={3}
        pb={disabledBottomPaddings ? 0 : undefined}
      >
        {dirty ? (
          <Button
            type='reset'
            sx={{ width: clearButtonWidth }}
            endIcon={<Clear />}
          >
            {clearText}
          </Button>
        ) : (
          <Button
            type='button'
            sx={{ width: backButtonWidth }}
            onClick={() => onGoBackClick?.()}
            startIcon={<ArrowBack />}
          >
            {backText}
          </Button>
        )}

        <LoadingButton
          type='submit'
          variant='contained'
          loading={loading}
          sx={{ width: submitButtonWidth }}
          endIcon={<ArrowForward />}
        >
          {submitText}
        </LoadingButton>
      </Grid>
    )
  );

export { StepperFooterFormControls };

export type { StepperFooterFormControlsProps };
