import { string } from 'yup';

import { createRule } from '@shared/form';

import { isEmailDomainValid } from './domain';

const validation = {
  email: createRule({
    name: 'email',
    schema: string()
      .label('Email')
      .required()
      .email()
      .test(
        'endsWith',
        'Must ends with `@pmi.com` or `@contracted.pmi.com` or `@rbhinc.ca` or `@sampoerna.com`',
        isEmailDomainValid
      )
  }),

  firstName: createRule({
    name: 'firstName',
    schema: string().label('First Name').required().min(2).max(64)
  }),

  lastName: createRule({
    name: 'lastName',
    schema: string().label('Last Name').required().min(2).max(64)
  }),

  password: createRule({
    name: 'password',
    schema: string()
      .label('Password')
      .required()
      .min(8)
      .max(255)
      .matches(/(?=.*\d)/g, '${path} must contains at least 1 number')
      .matches(
        /(?=.*[a-z])/g,
        '${path} must contains at least 1 lowercase latin letter'
      )
      .matches(
        /(?=.*[A-Z])/g,
        '${path} must contains at least 1 uppercase latin letter'
      )
      .matches(
        /(?=.*[\^$*.\[\]{}()?\-“"!@#%&/\\,><’':;|_~`+=])/g,
        '${path} must contains at least 1 special character'
      )
  }),

  code: createRule({
    name: 'code',
    schema: string().label('Code').matches(/^\d+$/, 'Only numbers').length(6)
  })
};

export { validation };
