import { createEvent, sample } from 'effector';

import { routes } from '@shared/config';

import { pushFx } from '@shared/history';

const mounted = createEvent();

sample({
  clock: mounted,
  target: pushFx.prepend(() => routes.auth.login())
});

export { mounted };
