const extensionMap = {
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx'
  ],
  'application/vnd.ms-powerpoint': ['.ppt']
};

const validExtension = Object.values(extensionMap).map(([extension]) =>
  extension.slice(1)
);

export { validExtension, extensionMap };
