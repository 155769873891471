import { Grid, Paper } from '@mui/material';

import type { GridProps } from '@mui/material/Grid/Grid';

import type { ForwardedRef } from 'react';

import { createView } from '@shared/lib/view';

const Container = createView<
  GridProps & { innerRef?: ForwardedRef<HTMLDivElement> }
>().view(({ innerRef, ...props }) => (
  <Grid
    ref={innerRef}
    container
    flex={1}
    mb={2}
    flexDirection='column'
    position='relative'
    component={Paper}
    elevation={3}
    gap={3}
    {...props}
  />
));

export { Container };
