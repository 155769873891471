import { Grid } from '@mui/material';

import { combine } from 'effector';

import type { ReactNode } from 'react';

import { $$session } from '@entities/session';

import { routes } from '@shared/config';

import { createView } from '@shared/lib/view';

import { TabsRouter } from '@shared/ui';

import { tabs } from './main-layout.config';

const MainLayout = createView<{
  children: ReactNode;
  hideTabs?: boolean;
}>()
  .units({
    tabs: combine(
      $$session.$isUploadAllowed,
      $$session.$isCustomRole,
      (isAllowed, isCustomRole) =>
        tabs.map(tab => {
          if (tab.route === routes.upload()) {
            return { ...tab, disabled: !isAllowed };
          }

          if (tab.route === routes.directories()) {
            return { ...tab, disabled: isCustomRole };
          }

          return tab;
        })
    )
  })
  .view(({ children, tabs, hideTabs }) => (
    <Grid container flexDirection='column' gap={2} flex={1} px={5}>
      {!hideTabs && <TabsRouter tabs={tabs} />}

      {children}
    </Grid>
  ));

export { MainLayout };
