import type { Effect } from 'effector';

import { attach } from 'effector';

import type { DownloadFileDto, GetDocsDto } from '@shared/api';

import { api } from '@shared/api';

const getDocsFx = attach({
  effect: api.docs.getDocs as unknown as Effect<void, GetDocsDto>
});

const downloadFileFx = attach({
  effect: api.files.downloadFile as unknown as Effect<DownloadFileDto, Blob>
});

export { getDocsFx, downloadFileFx };
