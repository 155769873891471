import { LoadingButton } from '@mui/lab';

import { Button, Grid, Paper } from '@mui/material';

import type { PropsWithChildren, ReactNode } from 'react';

import { createView } from '@shared/lib/view';

type DataTableFooterProps = PropsWithChildren<{
  resetText: ReactNode;
  submitText: ReactNode;

  onReset: () => unknown;
  onSubmit: () => unknown;

  isSubmitting?: boolean;

  disabledSubmit?: boolean;

  width?: number | string;

  hide?: boolean;
}>;

const DataTableFooter = createView<DataTableFooterProps>().view(
  ({
    resetText,
    onReset,
    onSubmit,
    isSubmitting,
    submitText,
    width,
    disabledSubmit,
    hide,
    children
  }) => (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      p={1.5}
      component={Paper}
      elevation={3}
    >
      {!hide ? (
        <Button
          variant='contained'
          color='error'
          disabled={isSubmitting}
          onClick={() => onReset()}
          sx={width ? { width } : {}}
        >
          {resetText}
        </Button>
      ) : (
        <div />
      )}

      {children}

      {!hide ? (
        <LoadingButton
          type='button'
          color='success'
          variant='contained'
          disabled={disabledSubmit}
          loading={isSubmitting}
          onClick={() => onSubmit()}
          sx={width ? { width } : {}}
        >
          {submitText}
        </LoadingButton>
      ) : (
        <div />
      )}
    </Grid>
  )
);

export { DataTableFooter };

export type { DataTableFooterProps };
