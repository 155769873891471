import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Done,
  ReportProblem
} from '@mui/icons-material';

import { Button, CircularProgress, Grid, Tooltip } from '@mui/material';

import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer
} from '@mui/x-data-grid';

import { createView } from '@shared/lib/view';

import {
  $errorMap,
  $onlyOneRowExist,
  $rows,
  addLinkClicked,
  linkChanged,
  page,
  removeLinkClicked
} from './model';

import type { SharePointLinkStatus } from './types';

const UploadLinksToolbar = createView()
  .units({ onAddClick: addLinkClicked })
  .view(({ onAddClick }) => (
    <Grid component={GridToolbarContainer} container justifyContent='flex-end'>
      <Button
        onClick={() => onAddClick()}
        startIcon={<AddIcon />}
        variant='text'
        size='small'
      >
        Add link
      </Button>
    </Grid>
  ));

const UploadLinksStep = createView()
  .units({
    rows: $rows,
    onlyOneRowExist: $onlyOneRowExist,
    errors: $errorMap,
    onRowDelete: removeLinkClicked,
    onRowChange: linkChanged
  })
  .open(page.mounted)
  .view(({ rows, errors, onlyOneRowExist, onRowDelete, onRowChange }) => (
    <DataGrid
      sx={{ '& .url-cell': { pl: 3 } }}
      rows={rows}
      columns={[
        {
          type: 'string',
          field: 'url',
          headerName: 'URL',
          flex: 1,
          headerClassName: 'url-cell',
          cellClassName: 'url-cell',
          editable: true,
          sortable: false
        },
        {
          field: 'status',
          headerName: 'Status',
          align: 'center',
          headerAlign: 'center',
          renderCell: params => (
            <Status
              status={params.row.status}
              errorMessage={errors[params.row.id]}
            />
          ),
          sortable: false
        },
        {
          field: 'actions',
          type: 'actions',
          getActions: params => [
            <GridActionsCellItem
              key='delete'
              icon={
                <Tooltip title='Delete'>
                  <DeleteIcon />
                </Tooltip>
              }
              label='Delete'
              onClick={() => onRowDelete(params.row)}
              disabled={onlyOneRowExist}
            />
          ]
        }
      ]}
      processRowUpdate={row => {
        onRowChange(row);

        return row;
      }}
      disableColumnMenu
      disableColumnFilter
      disableVirtualization
      disableSelectionOnClick
      hideFooter
      experimentalFeatures={{ newEditingApi: true }}
      components={{ Toolbar: UploadLinksToolbar }}
    />
  ));

const Status = createView<{
  status?: SharePointLinkStatus;
  errorMessage?: string;
}>().view(({ status, errorMessage }) => {
  switch (status) {
    case 'pending': {
      return (
        <Tooltip title='Searching...'>
          <CircularProgress size={24} />
        </Tooltip>
      );
    }

    case 'success': {
      return (
        <Tooltip title='File founded'>
          <Done color='success' />
        </Tooltip>
      );
    }

    case 'error': {
      return (
        <Tooltip title={errorMessage ?? ''}>
          <ReportProblem color='error' />
        </Tooltip>
      );
    }

    default: {
      return null;
    }
  }
});

export { UploadLinksStep };
