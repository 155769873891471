import { createEvent, sample } from 'effector';

import { reset } from 'patronum';

import type { FileError, FileRejection } from 'react-dropzone';

import { ErrorCode } from 'react-dropzone';

import { FileAdapter, fileModel } from '@entities/file';

import { notification } from '@shared/notification';

import { validExtension } from './config';

const unmounted = createEvent();

const fileInputChanged = createEvent<File[] | null>();
const fileInputErrored = createEvent<FileRejection[]>();

sample({
  clock: fileInputChanged,
  filter: Boolean,
  fn: filesList =>
    filesList
      .map(FileAdapter.fromLocal)
      .filter(file => validExtension.includes(file.fileType)),
  target: fileModel.localFilesUploaded
});

notification({
  clock: fileInputErrored,
  message: errors =>
    errors.reduce((str, { file, errors }) => {
      const mapErrors = (error: FileError) => {
        switch (error.code) {
          case ErrorCode.FileInvalidType: {
            return `Must be one of valid extensions: ${validExtension.join(
              ', '
            )}.`;
          }

          case ErrorCode.FileTooLarge: {
            return `File size greater than 25MB.`;
          }

          default: {
            return error.message;
          }
        }
      };

      return `${str}File "${file.name}" not uploaded. Message: ${errors
        .map(mapErrors)
        .join(' ')}\n`;
    }, ''),
  mode: 'error'
});

reset({
  clock: unmounted,
  target: fileModel.$localFiles
});

export { fileInputChanged, unmounted, fileInputErrored };
