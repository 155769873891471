import type { ChangeEvent } from 'react';

import type { FileMeta, LocalFile } from '@entities/file';

const getFilesFromEvent = (event: ChangeEvent<HTMLInputElement>) => {
  if (!event.target.files) return null;

  const files = Array.from(event.target.files);

  event.target.value = '';

  return files;
};

class UploadFilesLocalAdapter {
  public static fromFile({
    file,
    viewer
  }: {
    file: LocalFile;
    viewer: string;
  }): FileMeta {
    return {
      id: file.id,
      reviewId: '',
      name: file.fileName,
      type: file.fileType,
      from: viewer,
      function: '',
      market: '',
      project: '',
      serviceMonth: (
        new Date(file.original.lastModified).getMonth() + 1
      ).toString(),
      serviceYear: new Date(file.original.lastModified)
        .getFullYear()
        .toString(),
      validationBy: '',
      validationDate: ''
    };
  }
}

export { getFilesFromEvent, UploadFilesLocalAdapter };
